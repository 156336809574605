import styled from "styled-components";
import { useEffect, useState } from "react";
import { CupomT } from "../../services/types";
import { useLocation, useNavigate } from "react-router-dom";
import { updateCoupon } from "../../services/couponService";
import DatePicker from "../../components/DatePicker";
import ClusterSelect from "../../components/ClusterSelect";

const coupomTypes = {
  default: 0,
  percent: 1,
  fixed: 2,
  freight: 3,
};

export default function EditCoupom() {
  const location = useLocation();
  const navigator = useNavigate();
  const state = location.state as CupomT;

  const [nome, setNome] = useState(state?.cup_st_codigo.toString());
  const [description, setDescription] = useState(state?.cup_st_descricao);
  const [value, setValue] = useState(parseInt(state?.cup_de_valor));
  const [percent, setPercent] = useState(Number(state?.cup_de_porcentagem));
  const [minValue, setMinValue] = useState(
    parseInt(state?.cup_de_minvalue) ? parseInt(state?.cup_de_minvalue) : 0
  );
  const [maxUsage, setMaxUsage] = useState(
    state?.cup_in_usomaximo ? state?.cup_in_usomaximo : 0
  );
  const [freeFreight, setFreeFreight] = useState<boolean>(
    state?.cup_bo_fretegratis
  );
  const [isActive, setIsActive] = useState<boolean>(state?.cup_bo_ativo);
  const [type, setType] = useState(coupomTypes.default);
  const [startDate, setStartDate] = useState<string>(
    state?.cup_dt_startDate ? state.cup_dt_startDate : ""
  );
  const [endDate, setEndDate] = useState<string>(
    state?.cup_dt_endDate ? state.cup_dt_endDate : ""
  );
  const [clusterBlackList, setClusterBlackList] = useState<string[]>(
    state?.blacklistedClusters.length
      ? state.blacklistedClusters.map((cluster) => cluster.clu_in_codigo)
      : []
  );

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const objCupom = {
      cup_bo_ativo: isActive,
      cup_in_codigo: state?.cup_in_codigo,
      cup_st_codigo: nome,
      cup_st_descricao: description,
      cup_de_valor: value,
      cup_de_porcentagem: percent,
      cup_de_minvalue: minValue,
      cup_in_usomaximo: maxUsage,
      cup_bo_fretegratis: freeFreight,
      cup_dt_startDate: startDate,
      cup_dt_endDate: endDate,
      blacklistedClusters: clusterBlackList,
    };
    await updateCoupon(objCupom);
    navigator("/couponsList");
  };

  useEffect(() => {
    if (type === coupomTypes.percent) {
      setValue(0);
      setFreeFreight(false);
    }
    if (type === coupomTypes.fixed) {
      setPercent(0);
      setFreeFreight(false);
    }
    if (type === coupomTypes.freight) {
      setFreeFreight(true);
      setValue(0);
      setPercent(0);
    }
  }, [type]);

  return (
    <section className="wrapper">
      <form onSubmit={(event) => handleSubmit(event)}>
        <InputContainer>
          <Label>Nome do cupom</Label>
          <Input
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            disabled={true}
          />
        </InputContainer>

        <InputContainer>
          <Label>Descrição</Label>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputContainer>

        <InputContainer>
          <Label>Tipo</Label>
          <Select onChange={(e) => setType(Number(e.target.value))}>
            <option value={coupomTypes.default}>Selecione</option>
            <option value={coupomTypes.percent}>Porcentagem</option>
            <option value={coupomTypes.fixed}>Valor fixo</option>
            <option value={coupomTypes.freight}>Frete grátis</option>
          </Select>
        </InputContainer>

        <InputContainer>
          <Label>Porcentagem de desconto</Label>
          <Input
            value={percent}
            onChange={(e) => setPercent(Number(e.target.value))}
            type="number"
            disabled={type !== coupomTypes.percent}
          />
        </InputContainer>

        <InputContainer>
          <Label>Valor de desconto</Label>
          <Input
            value={value.toString()}
            onChange={(e) => setValue(Number(e.target.value))}
            type="number"
            disabled={type !== coupomTypes.fixed}
          />
        </InputContainer>

        <InputContainer>
          <Label>Valor mínimo</Label>
          <Input
            value={minValue}
            type="number"
            onChange={(e) => setMinValue(Number(e.target.value))}
          />
        </InputContainer>

        <InputContainer>
          <Label>Uso máximo</Label>
          <Input
            value={maxUsage}
            type="number"
            onChange={(e) => setMaxUsage(Number(e.target.value))}
          />
        </InputContainer>

        <InputContainer>
          <Label>Frete Grátis</Label>
          <CheckBox
            type="checkbox"
            checked={freeFreight}
            disabled={type !== coupomTypes.freight}
          />
        </InputContainer>

        <InputContainer>
          <Label>Ativo</Label>
          <CheckBox
            type="checkbox"
            checked={isActive}
            onChange={(e) => setIsActive(e.target.checked)}
          ></CheckBox>
        </InputContainer>

        <InputContainer>
          <Label>Data Início</Label>
          <DatePicker
            value={
              startDate && new Date(startDate).toISOString().substring(0, 10)
            }
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </InputContainer>

        <InputContainer>
          <Label>Data Final</Label>
          <DatePicker
            value={endDate && new Date(endDate).toISOString().substring(0, 10)}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </InputContainer>

        <InputContainer>
          <Label>Blacklist de copons</Label>
          <ClusterSelect
            setState={setClusterBlackList}
            initialValue={clusterBlackList}
          />
        </InputContainer>

        <Button
          style={{ marginRight: 10 }}
          onClick={() => navigator("/couponsList")}
        >
          VOLTAR
        </Button>
        <Button disabled={loading} type="submit">
          SALVAR
        </Button>
      </form>
    </section>
  );
}

const Label = styled.p`
  font-size: 1.25rem;
  font-weight: bold;
  display: inline;
  margin-right: 1rem;
`;

const Input = styled.input`
  width: ${(props) => (props.type === "number" ? "75px" : "auto")};
  border-radius: 7px;
  border: ${(props) =>
    props.disabled ? "1px solid #a8a8a8;" : "1px solid #85b074;"};
  color: ${(props) => props.disabled && "#c6c6c6;"};
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

const CheckBox = styled.input``;

const Select = styled.select`
  width: 8.5rem;
  border-radius: 7px;
  border: 1px solid #85b074;
  font-size: 1rem;
  padding: 0.5rem;
  &:focus {
    outline: none;
    border: 1px solid #6d9962;
  }
  text-align: flex-start;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem;
  font-size: 1.5rem;
  background-color: ${(props) => (props.disabled ? "#a7a7a7" : "#85b074")};
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  cursor: ${(props) => (props.disabled ? "normal" : "pointer")};
`;

const ErrorText = styled.p`
  font-size: 1.5rem;
  color: red;
`;
