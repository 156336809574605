import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

interface IGetClientCarts {
  page?: number;
  limit?: number;
  ref?: string;
  startDate?: string;
  endDate?: string;
}
export async function getClientCarts({
  limit,
  page,
  ref = "",
  startDate,
  endDate,
}: IGetClientCarts) {
  try {
    const params = new URLSearchParams({
      page: String(page || 1),
      limit: String(limit || 15),
      ref,
      ...(startDate ? { startDate: String(startDate) } : {}),
      ...(endDate ? { endDate: String(endDate) } : {}),
    });

    const result = await axios.get(
      `${configs.apiBaseUrl}/api/client/carts?${params.toString()}`
    );

    return {
      carts: result?.data?.clientCarts?.carts,
      _metadata: result?.data?.clientCarts?._metadata,
    };
  } catch (error) {
    toast.error(`Erro ao tentar carregar carrinhos abandonados \n", ${error}`);
  }
}
