import axios from "axios";
import configs from "../appConfigs";

interface IPutClient {
  id: number;
  cli_st_cpf?: string;
  cli_st_cnpj?: string;
  cli_st_email: string;
  cli_ch_sexo: string;
  cli_dt_datanascimento: string;
  cli_st_celular: string;
  cli_st_nome: string;
  shouldUpdateMln: boolean;
}

export async function putClient(client: IPutClient) {
  const { id, ...clientData } = client;

  const { data } = await axios.put(`${configs.apiBaseUrl}/admin/client/${id}`, {
    ...clientData,
  });

  return data;
}
