import React from "react";
import { BoldText, Separator, TextContainer, Title } from "./style";
import { IFreight } from "../../../services/types";
import { formatPrice } from "../../../utils/price";
import { formatDate } from "../../../utils/data";

const FreightDetails: React.FC<IFreight> = ({
  price,
  time,
  type,
  deliveryTime,
  shippingCompany,
}) => {
  return (
    <>
      <Separator />
      <Title>Detalhes do frete: </Title>
      <TextContainer>
        <BoldText>Valor do Frete: </BoldText>
        {formatPrice(Number(price))}
      </TextContainer>
      <TextContainer>
        <BoldText>Prazo de Frete: </BoldText>
        {time}
      </TextContainer>
      <TextContainer>
        <BoldText>Transportadora: </BoldText>
        {`${shippingCompany} - ${type}`}
      </TextContainer>
      <TextContainer>
        <BoldText>Prazo Estimado: </BoldText>
        {formatDate(new Date(deliveryTime).toString())}
      </TextContainer>
    </>
  );
};

export default FreightDetails;
