import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import ClientData from "./ClientData";
import ProductList from "./ProductList";
import PaymentSummary from "./PaymentSummary";
import { OrderT } from "../../services/types";
import { getPedidoById } from "../../services/ordersService";
import FreightDetails from "./FreightDetails";

export default function OrderDetails() {
  const { id } = useParams();
  const [order, setOrder] = useState<OrderT>();

  async function fetchOrders() {
    const _order = await getPedidoById(id);

    setOrder(_order);
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <section className="wrapper">
      {order ? (
        <div>
          <Header order={order} />

          <ClientData client={order.cliente} />

          <ProductList products={order.produtos} />

          <PaymentSummary
            cupom={order.cupom}
            values={order.valores}
            order={order}
          />
          <FreightDetails
            type={order.freight.type}
            time={order.freight.time}
            deliveryTime={order.freight.deliveryTime}
            price={order.freight.price}
            shippingCompany={order.freight.shippingCompany}
          />
        </div>
      ) : (
        <div>carregando...</div>
      )}
    </section>
  );
}
