import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { getProducts } from "../../services/productsService";
import { formatedProduct } from "../../utils/formatProductsFromApiToListItem";

export default function ProductSelectorForRestriction({
  setProd,
}: {
  setProd: (arg0: formatedProduct) => void;
}) {
  const [prodSearch, setProdSearch] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  async function handleProductSearch() {
    if (!prodSearch) return;

    setIsLoading(true);
    try {
      setProd(await getProducts({ sku: prodSearch }));
    } catch (error) {
      toast.warning("Nenhum produto encontrado!");
    }

    setIsLoading(false);
  }

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleProductSearch();
    }
  };

  return (
    <>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Digite o SKU do produto"
            placeholder="Digite um SKU aqui"
            setValue={setProdSearch}
            type="text"
            value={prodSearch}
            onKeyDown={handleKeyPress}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <Button
          variant="light"
          onClick={handleProductSearch}
          disabled={loading || !prodSearch.length}
        >
          Buscar
        </Button>
      </DivFlex>
    </>
  );
}

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 3rem;

  button {
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
`;

const DivBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ProductImage = styled.img`
  max-width: 84px;
  max-height: 84px;
  display: block;
  object-fit: contain;
`;
