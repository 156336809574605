export default function handleSetCep({
  value,
  setState,
}: {
  value: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
}) {
  setState(
    (value.length > 9 ? value.slice(0, 9) : value).replace(
      /(\d{5})(?=\d)/g,
      "$1-"
    )
  );
}
