import React, { InputHTMLAttributes, ChangeEvent } from "react";
import styled from "styled-components";

interface SonomaInputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  title: string;
  value: any;
  setValue: Function;
}

interface SonomaTextareaProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  width?: string | number | undefined;
  height?: string | number | undefined;
}

export default function FormTextArea(props: SonomaInputProps) {
  function handleValueChanged(e: ChangeEvent<HTMLTextAreaElement>) {
    props.setValue(e.target.value);
  }

  return (
    <>
      <Label htmlFor={props.title}>{props.title}</Label>
      {props.maxLength ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextArea
            {...props}
            name={props.title}
            onChange={handleValueChanged}
          />
          <SmallText>
            {props.value.length}/{props.maxLength}
          </SmallText>
        </div>
      ) : (
        <TextArea {...props} name={props.title} onChange={handleValueChanged} />
      )}
    </>
  );
}

const Label = styled.label`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
`;

const TextArea = styled.textarea<SonomaTextareaProps>`
  color: ${(props) => props.theme.colors.text_dark};
  width: ${(props) => props.width || "250px"};
  height: ${(props) => props.height || "2rem"};
  border: 1px solid ${(props) => props.theme.colors.border};
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
  padding: 0.25rem;
  border-radius: 2px;
`;

const SmallText = styled.p`
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: #999;
`;
