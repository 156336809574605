import { InputHTMLAttributes, ChangeEvent } from "react";
import styled from "styled-components";

interface SonomaInputProps extends InputHTMLAttributes<HTMLInputElement> {
  title: string;
  value: any;
  setValue: Function;
  width: string;
  height: string;
  borderRadius: string;
}

export default function FormInput(props: SonomaInputProps) {
  function handleValueChanged(e: ChangeEvent<HTMLInputElement>) {
    props.setValue(e.target.value);
  }

  return (
    <>
      <Label htmlFor={props.title}>{props.title}</Label>
      {props.maxLength ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Input {...props} name={props.title} onChange={handleValueChanged} />
          <SmallText>
            {props.value.length}/{props.maxLength}
          </SmallText>
        </div>
      ) : (
        <Input {...props} name={props.title} onChange={handleValueChanged} />
      )}
    </>
  );
}

const Label = styled.label`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
  font-weight: 400;
`;

const Input = styled.input<SonomaInputProps>`
  color: ${(props) => props.theme.colors.text_dark};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid ${(props) => props.theme.colors.border};
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
  padding: 0.5rem;
  border-radius: ${(props) => props.borderRadius};
`;

const SmallText = styled.p`
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: #999;
`;
