import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";
import { formatDateIntoDayMonthYear } from "../utils/formatDateIntoDayMonthYear";
import { getMonthAbbreviation } from "../utils/getMonthAbbreviation";
import {
  CreatePlan,
  ResponseSignaturesPlans,
} from "../pages/SignatureApp/types";

export async function getSignaturesByMonth() {
  try {
    const signaturesByMonth = await axios.get(
      `${configs.apiBaseUrl}/api/signature/monthly`
    );

    if (!signaturesByMonth?.data?.signatures) return [];
    let formattedSignatures: { month: any; [key: string]: string }[] = [];

    signaturesByMonth.data.signatures.forEach(
      (entry: { month: number; reason: string; count: any }) => {
        if (
          !formattedSignatures.filter(
            (formattedEntry) => formattedEntry.month === entry.month
          )?.[0]
        )
          return formattedSignatures.push({
            month: entry.month,
            [entry.reason.replace("Sonoma Signature - ", "")]: entry.count,
          });

        const entryMonth =
          formattedSignatures[
            formattedSignatures.findIndex((obj) => obj.month === entry.month)
          ];
        entryMonth[entry.reason.replace("Sonoma Signature - ", "")] =
          entry.count;
      }
    );

    return formattedSignatures.map((item) => ({
      ...item,
      month: getMonthAbbreviation(item.month),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesByDate() {
  try {
    const signaturesByDate = await axios.get(
      `${configs.apiBaseUrl}/api/signature/daily`
    );

    if (!signaturesByDate?.data?.signatures) return [];
    let formattedSignatures: { date: any; [key: string]: string }[] = [];

    signaturesByDate.data.signatures.forEach(
      (entry: { date: number; reason: string; count: any }) => {
        if (
          !formattedSignatures.filter(
            (formattedEntry) => formattedEntry.date === entry.date
          )?.[0]
        )
          return formattedSignatures.push({
            date: entry.date,
            [entry.reason.replace("Sonoma Signature - ", "")]: entry.count,
          });

        const entryMonth =
          formattedSignatures[
            formattedSignatures.findIndex((obj) => obj.date === entry.date)
          ];
        entryMonth[entry.reason.replace("Sonoma Signature - ", "")] =
          entry.count;
      }
    );

    return formattedSignatures.map((entry) => ({
      ...entry,
      date: formatDateIntoDayMonthYear(entry.date),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesCancelledByDate() {
  try {
    const signaturesByDate = await axios.get(
      `${configs.apiBaseUrl}/api/signature/dailyCancelled`
    );

    if (!signaturesByDate?.data?.signatures) return [];
    let formattedSignatures: { date: any; [key: string]: string }[] = [];

    signaturesByDate.data.signatures.forEach(
      (entry: { date: number; reason: string; count: any }) => {
        if (
          !formattedSignatures.filter(
            (formattedEntry) => formattedEntry.date === entry.date
          )?.[0]
        )
          return formattedSignatures.push({
            date: entry.date,
            [entry.reason.replace("Sonoma Signature - ", "")]: entry.count,
          });

        const entryMonth =
          formattedSignatures[
            formattedSignatures.findIndex((obj) => obj.date === entry.date)
          ];
        entryMonth[entry.reason.replace("Sonoma Signature - ", "")] =
          entry.count;
      }
    );

    return formattedSignatures.map((entry) => ({
      ...entry,
      date: formatDateIntoDayMonthYear(entry.date),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesCounted() {
  try {
    const signatures = await axios.get(`${configs.apiBaseUrl}/api/signature`);

    if (!signatures.data?.signatures?.length) return [];

    return signatures.data.signatures.map((item: { name: string }) => ({
      ...item,
      name: item.name
        .replace("Sonoma Signature - ", "")
        .replace(" garrafas", ""),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesProfit() {
  try {
    const signatures = await axios.get(
      `${configs.apiBaseUrl}/api/signature/profit`
    );

    if (!signatures.data?.signatures?.length) return [];

    return signatures.data.signatures.map((item: { Assinatura: string }) => ({
      ...item,
      Assinatura: item.Assinatura.replace("Sonoma Signature - ", "").replace(
        " garrafas",
        ""
      ),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesStatus() {
  try {
    const signatures = await axios.get(
      `${configs.apiBaseUrl}/api/signature/status`
    );

    if (!signatures.data?.signatures?.length) return [];

    return signatures.data.signatures.map((item: { Assinatura: string }) => ({
      ...item,
      Assinatura: item.Assinatura.replace("Sonoma Signature - ", "").replace(
        " garrafas",
        ""
      ),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesForms() {
  try {
    const signatures = await axios.get(
      `${configs.apiBaseUrl}/api/signature/forms`
    );

    if (!signatures.data?.signatures?.length) return [];

    return signatures.data.signatures.map((item: { Assinatura: string }) => ({
      ...item,
      Assinatura: item.Assinatura.replace("Sonoma Signature", "").replace(
        " garrafas",
        ""
      ),
    }));
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getSignaturesPayerStatus() {
  try {
    const {
      data: { signatures },
    } = await axios.get(`${configs.apiBaseUrl}/api/signature/payersStatus`);

    return signatures;
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function getsigaturesCombinedDataResponse() {
  try {
    const signatures = await axios.get(
      `${configs.apiBaseUrl}/api/signature/combinedData`
    );

    if (!signatures.data?.signatures?.length) return [];

    return signatures.data.signatures;
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar dados do signature da API \n", ${error}`
    );
  }
}

export async function triggerDatabaseUpdate() {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/mercadopago/updateentries`);
    await axios.post(`${configs.apiBaseUrl}/api/hubspot/updateentries`);
  } catch (error) {
    toast.error(`Erro ao atualizar dados do signature \n", ${error}`);
  }
}

export async function getSignaturesPlans(): Promise<
  ResponseSignaturesPlans[] | undefined
> {
  try {
    const result = await axios.get(`${configs.appApiBaseUrl}/signature/plans`);
    return result.data.data;
  } catch (error: any) {
    toast.error(`Erro ao capturar dados do signature \n", ${error.message}`);
  }
}

export async function getAvailableInvoicing() {
  try {
    const result = await axios.get(
      `${configs.appApiBaseUrl}/signature/available/invoicing`
    );
    return result.data.data;
  } catch (error: any) {
    toast.error(
      `Erro ao liberar faturamento do signature \n", ${error.message}`
    );
  }
}

export async function sendSignaturesToMln() {
  try {
    const response = await axios.post(
      `${configs.apiBaseUrl}/api/invoicing/signatures`,
      {}
    );

    if (response.status === 200) {
      toast.success("Signatures enviado para millenium");
    }
  } catch (error: any) {
    toast.error(
      `Erro ao enviar faturamento do signature \n", ${error.message}`
    );
  }
}

export async function inactivePlan(item: ResponseSignaturesPlans) {
  try {
    const result = await axios.post(
      `${configs.apiBaseUrl}/api/signature/inactivePlans`,
      item
    );
    if (result.status < 400)
      toast.success(`Plano de assinatura desativado com sucesso!`, {
        pauseOnFocusLoss: false,
      });
  } catch (error: any) {
    toast.error(`Erro ao inativar signature plan \n", ${error.message}`);
  }
}

export async function createPlan(body: CreatePlan) {
  try {
    const result = await axios.postForm(
      `${configs.apiBaseUrl}/api/signature/createPlan`,
      body
    );
    toast.success("Plano de assinatura criado com sucesso");
    return result.data.data.sig_in_codigo;
  } catch (error: any) {
    toast.error(`Erro ao criar dados do signature plan \n", ${error.message}`);
  }
}

export async function deleteProductFromSignature({
  planId,
  productId,
  productMonth,
}: {
  planId: string | undefined;
  productId: string | undefined;
  productMonth: number | undefined;
}) {
  try {
    if (!planId || !productId || !productMonth) return;

    return await axios.delete(
      `${configs.apiBaseUrl}/api/signature/${planId}/product/${productId}/month/${productMonth}`
    );
  } catch (error: any) {
    toast.error(
      `Erro ao tentar excluir um produto da assinatura \n", ${error.message}`
    );
  }
}

export async function updateSignature({
  planId,
  newProducts,
  month,
  year,
  amount,
}: {
  planId: string;
  newProducts: [];
  month: number;
  year: number;
  amount: number;
}) {
  try {
    return await axios.post(
      `${configs.apiBaseUrl}/api/signature/updateProducts`,
      {
        products: newProducts?.map((prod) => ({
          pro_st_sku: prod["Sku Mln"],
          sgp_in_month: month,
          sgp_in_year: year,
          sig_in_codigo: planId,
          sgp_in_amount: amount,
        })),
      }
    );
  } catch (error) {
    toast.error(
      `Erro ao tentar adicionar um produto na assinatura\n", ${error}`
    );
  }
}

export async function getProductsSignature(id: number) {
  try {
    const result = await axios.get(
      `${configs.appApiBaseUrl}/signature/products/${id}`
    );
    return result.data.data;
  } catch (error: any) {
    toast.error(`Erro ao pegar dados do signature plan \n", ${error.message}`);
  }
}
