import {
  Button,
  Drawer,
  ClippedDrawer,
  Table,
  ModalComponent,
  DragAndDrop,
  Checkbox,
  Input,
  Options,
} from "sonoma-design-system";

// Adicione a tipagem ao componente Button
interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  isStartIcon?: boolean;
  icon?: React.ReactNode;
  size: "large" | "medium" | "small";
  onClick?: () => void;
  outlined?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  children: unknown;
}

// Exporte o componente Button com as novas tipagens
export const DSButton: React.FC<ButtonProps> = Button;

interface OptionsProps {
  icon?: unknown;
  text: string;
  onClick?: () => void;
}

export const DSOptions: React.FC<{ options: OptionsProps[] }> = Options;

interface TableProps {
  /**
   * An array of rows to be displayed, each row must have an id property
   * which will be used to relate to its column.
   * Example:
   * [
   *  { name: 'Frozen yoghurt', calories: 159, fat: 6.0, carbs: 24, protein: 4.0 },
   *  { name: 'Ice cream sandwich', calories: 237, fat: 9.0, carbs: 37, protein: 4.3 },
   *  { name: 'Eclair', calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
   *  { name: 'Cupcake', calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
   *  { name: 'Gingerbread', calories: 356, fat: 16.0, carbs: 49, protein: 3.9 },
   * ];
   */
  rows: Array<{ id: number | string; [prop: string]: unknown }>;

  /**
   * Table columns. Must have an id and a title. It also can be a react
   * component.
   * Example:
   * [
   *  { id: 'name', title: 'Dessert (100g serving)' },
   *  { id: 'calories', title: 'Calories' },
   *  { id: 'fat', title: 'Fat (g)' },
   *  { id: 'carbs', title: 'Carbs (g)' },
   *  { id: 'protein', title: 'Protein (g)' },
   * ]
   */
  columns: Array<{
    id: string;
    title: string;
    component?: (props) => ReactElement<unknown>;
  }>;

  /**
   * Paging data
   */
  paging?: {
    limit?: number;
    page?: number;
    /** total items */
    total?: number;
  };

  /**
   * Function to handle page change, it must receive a page parameter
   */
  onChangePage?: (page: number) => unknown;

  /**
   * If true, paging component will be displayed. Default: true
   */
  showPaging?: boolean;

  /**
   * Table ordering data
   */
  ordering?: {
    /** current column to order by */
    order_by?: string;
    /** asc | desc  */
    order?: string;
    /** array of available columns to order by */
    availableOrderBy?: Array<{ [prop: string]: string }>;
  };

  /**
   * Function to handle order change, it must be able to receive a ordering
   * like the example:
   * { order_by: 'col', order: 'asc' }
   */
  onChangeOrder?: () => unknown;

  /**
   * If true, the search container will be displayed. Default: true
   */
  showSearch?: boolean;

  /**
   * Component to be displayed on table top right position
   */
  headerComponent?: (props) => ReactNode;

  /**
   * Function to handle search form submit. Must be able to receive a single
   * param which is the value of the search
   */
  onSearch?: (ref: string) => unknown;

  /**
   * Placeholder to search input. Default: Pesquisar
   */
  searchPlaceholder?: string;

  /**
   * Value for input start with something on search
   */
  refValue?: string;

  /**
   * Value for title on the top of table
   */
  headerTitle?: string;

  /**
   * Can be used to transform the input in numeric
   */
  isNumericInputProps?: boolean;

  // IMPORTANT NOTE: TO APPLY MASK AND FORMAT U NEED TO SET INPUT ISNUMERIC
  /**
   * Field Mask to blank value mask = '_' will show: _ _ _ . _ _ _ . _ _ _ - _ _
   */
  maskInputProps?: string;

  /**
   * Field Format. Example: (##) #####-####
   */
  formatInputProps?: string;
}

export const DSTable: React.FC<TableProps> = Table;

interface ModalProps {
  /**
   * The ID from modal
   */
  modalId: string;
  /**
   * The label from button
   */
  labelButton?: string;
  /**
   * Bool value for button fullWidth or not
   */
  fullWidthButton?: boolean;
  /**
   * Show modal button
   */
  showButton?: boolean;
  /**
   * Is modal open
   */
  open?: boolean;
  /**
   * Function to handle modal open state
   */
  setOpen?: () => void;
  /**
   * The size of the button.
   * Accepted sizes: 'large', 'medium', 'small'
   */
  buttonSize?: "large" | "medium" | "small";
  children: ReactElement;
}

export const DSModal: React.FC<ModalProps> = ModalComponent;

interface DragAndDropProps {
  /**
   * Array of uploaded files
   */
  files?: Array<File>;
  /**
   * Function to be called when files are dropped. It must be able to receive
   * a single param corresponding to the accepted files
   */
  onDrop: (file: Array<File>) => void;
  /**
   * icon for dragNdrop center image
   */
  icon: string;
  /**
   * boolean to show dragNdrop like button
   */
  isButton?: boolean;
  /**
   * button title when isButton = true
   */
  buttonTitle?: string;
  /**
   * body title when isButton = false
   */
  bodyTitle?: string;
  /**
   * body subtitle when isButton = false
   * _EXAMPLE:_
   * const teste = () => {
      return (
        <>
          <p>First P</p>
          <p>Second p</p>
        </>
        )
      }
   */
  bodySubtitle?: () => void;
}
export const DSDragAndDrop: React.FC<DragAndDropProps> = DragAndDrop;

interface InputProps {
  /**
   * The id provide to input
   */
  inputId?: string;
  /**
   * The text to show in placeholder
   */
  label?: string;
  /**
   * The text to show below the input
   */
  helperText?: string;
  /**
   * The error text to show below the
   *  input in red
   */
  errorText?: string;
  /**
   * Boolean to show errorText or not
   */
  error?: boolean;
  /**
   * The value of input
   */
  value: string;
  /**
   * Function to handle with value change
   */
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Boolean to show input fullWidth or not
   */
  fullWidth?: boolean;
  /**
   * Boolean to input transform in textarea
   */
  textArea?: boolean;
  /**
   * Minimum number of rows on input (usually used in textarea)
   */
  minRows?: number;
  /**
   * Maximum number of rows on input (usually used in textarea)
   */
  maxRows?: number;
  /**
   * Icon to be displayed inside the input
   */
  icon?: unknown;
  /**
   * Input size, must be one of (small, medium). Default: medium
   */
  size?: "large" | "medium" | "small";
  /**
   * Can be used to replace the label
   */
  placeholder?: string;
  /**
   * Can be used to show a avatar before input
   */
  avatar?: boolean;
  /**
   * Can be used to show two letter in avatar
   */
  avatarNam?: string;
  /**
   * Can be used to transform the input in numeric
   */
  isNumeric?: boolean;
  /**
   * Input type
   */
  type?: string;
  /**
   * _THIS FIELD WORKS JUST WHEN IS NUMERIC IS TRUE_
   * Field Format. Example: (##) #####-####
   */
  format?: string;
  /**
   * _THIS FIELD WORKS JUST WHEN IS NUMERIC IS TRUE_
   * Field Mask to blank value mask = "."  will show: (..) .....-....
   */
  mask?: string;
  /**
   * Prefix. Example: R$ 950,00 (R$)
   */
  prefix?: string;
  /**
   * suffix. Example: 95% (%)
   */
  suffix?: string;
  /**
   * title for an avatar description
   */
  avatarTitle?: string;
}

export const DSInput: React.FC<InputProps> = Input;

interface CheckboxProps {
  checkboxId?: string;
  label?: string;
  value?: boolean;
  handleChange?: () => void;
}

export const DSCheckbox: React.FC<CheckboxProps> = Checkbox;
interface ClippedDrawerProps {
  /**
   * The brandIcon to be with brandLabels
   */
  logo?: unknown;
  /**
   * Color from menu background
   */
  backgroundColor?: string;
  children?: unknown;
}

export const DSClippedDrawer: React.FC<ClippedDrawerProps> = ClippedDrawer;
interface MenuItemProps {
  label: string;
  to: string;
  Icon?: unknown;
  handleClick?: () => void;
}

interface DrawerProps {
  /**
   * Menu items to be displayed on button click.
   * Must follow the format: 
   * if u want to execute a function, pass a handleClick props
   * [{
        label: 'YourLabel',
        to: '/your/url/link',
        Icon: YourIcon,
        handleClick: YourFunction,
    },
    {
        label: 'YourLabel',
        to: '/your/url/link',
        Icon: YourIcon, 
    }]
   */
  menuItens?: Array<MenuItemProps>;
  /**
   * The element that will be used to render the items. The component
   * must be able to receive a 'to' prop, which will be the redirect link
   * Example: <Link />
   */
  as?: element;
  /**
   * The label to be your brandName
   */
  firstBrandLabel?: string;
  /**
   * The second label to be your subtitle below brandName
   */
  secondBrandLabel?: string;
  /**
   * The variant of drawer [permanent , persistent ,temporary]
   */
  variant?: "permanent" | "persistent" | "temporary";
  /**
   * The state to define if drawer menu is open or not
   * normally used when page is minor than 800 and hamburguer menu show
   * when use open state don't forget to use onOpen and onClose
   */
  openState?: boolean;
  /**
   * Function to change openState
   */
  onOpen?: () => void;
  /**
   * Function to close openState
   */
  onClose?: () => void;
}

export const DSDrawer: React.FC<DrawerProps> = Drawer;
