import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  padding: 50px 0;
  width: 100%;
  max-width: 1200px;
`;

export const DateFiltersContainer = styled.div`
  display: flex;
  justify-content: right;
  gap: 15px;
`;

export const FilterButton = styled.span`
  letter-spacing: 0.5px;
  cursor: pointer;
  font-weight: 700;
  color: #0000008a;

  &.active {
    border-bottom: 2px solid #85b074;
    color: #85b074;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Card = styled.div`
  width: 300px;
  padding: 40px 0;
  display: flex;
  background-color: #eeeeee;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`;

export const CardTitle = styled.b`
  font-size: 24px;
  font-weight: 700;
`;

export const CalendarContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const ButtonDescriptionContainer = styled.div`
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #85b074;
  padding: 10px;
  color: #85b074;

  &.bgc {
    background-color: #fff;
  }

  &:hover {
    background-color: #85b074;
    color: white;
  }
`;

export const DataSelectButton = styled.div`
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
`;

export const DataPickerContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
`;
