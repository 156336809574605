import styled from "styled-components";

export const Main = styled.div`
  width: 800px;
  max-height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 20px;
`;

export const Select = styled.select`
  width: 100px;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

export const ContainerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 16px;

  button {
    border: none;
    background: #85b074;
    border-radius: 0;
    height: 2.5rem;
    padding: 0 2rem;
    cursor: pointer;
    font-size: 14px;
    color: #3b3b3a;
    font-weight: 600;
  }

  button:disabled {
    background: #c8e2be;
  }

  span {
    padding: 10px;
  }
`;
