import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import handleSetCep from "../../utils/handleSetCep";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import DatePicker from "../../components/DatePicker";
import Dropdown from "../../components/BannerManager/Dropdown";
import {
  getShippingPromotion,
  updateOrCreateShippingPromotion,
} from "../../services/shippingPromotionsService";
import theme from "../../styles/colors";

const promotionTypes = [
  {
    title: "Desconto de Porcentagem",
    value: "Porcentagem",
    description:
      "A porcentagem de desconto é aplicada sobre o valor total do frete",
  },
  {
    title: "Desconto de Valor",
    value: "Desconto de Valor",
    description:
      "Esse tipo de desconto é aplicado de forma direta, sem depender do valor original do frete",
  },
  {
    title: "Valor Fixo",
    value: "Valor Fixo",
    description: "Esse valor vai substituir o valor original do frete",
  },
];

export default function PromotionShipping() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const [discount, setDiscount] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [cepStart, setCepStart] = useState<string>("");
  const [cepEnd, setCepEnd] = useState<string>("");
  const [startDate, setStartDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const date = new Date(new Date().getTime());
    return date.toISOString().split("T")[0];
  });
  const [promotionType, setPromotionType] = useState("");

  const [promotionId, setPromotionId] = useState<string | undefined>(id);

  const handleGetPromotion = async () => {
    if (!id) return;
    setIsWaitingApi(true);
    const promotion = await getShippingPromotion({ id });
    if (!promotion) return;

    handleSetCep({
      value: promotion.shp_in_inicio_cep.toString(),
      setState: setCepStart,
    });
    handleSetCep({
      value: promotion.shp_in_final_cep.toString(),
      setState: setCepEnd,
    });
    setPromotionId(promotion.shp_in_codigo);
    setName(promotion.shp_st_name);
    setStartDate(() => {
      const date = new Date(new Date(promotion.shp_dt_startDate).getTime());
      return date.toISOString().split("T")[0];
    });
    setEndDate(() => {
      const date = new Date(new Date(promotion.shp_dt_endDate).getTime());
      return date.toISOString().split("T")[0];
    });
    setPromotionType(promotion.shp_st_type);
    setDiscount(promotion.shp_in_amount);

    setIsWaitingApi(false);
  };

  const handleUpdateShippingPromotion = async () => {
    setIsWaitingApi(true);

    const res = await updateOrCreateShippingPromotion({
      promotionId,
      name,
      begin: cepStart.replace("-", ""),
      end: cepEnd.replace("-", ""),
      startDate,
      endDate,
      promotionType,
      discount,
    });
    if (res?.status && res.status < 400)
      toast.success(
        `Promoção ${promotionId ? "atualizada" : "criada"} com sucesso!`
      );
    setIsWaitingApi(false);
    navigate(`/promotions/shipping/all`);
  };

  useEffect(() => {
    handleGetPromotion();
  }, []);

  return (
    <section className="wrapper">
      <Title>Promoção de Frete</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Nome"
            setValue={setName}
            value={name}
            maxLength={30}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Início do Range de CEP"
            setValue={(value: string) =>
              handleSetCep({
                value: value.length > 9 ? value.slice(0, 9) : value,
                setState: setCepStart,
              })
            }
            value={cepStart}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Fim do Range de CEP"
            setValue={(value: string) =>
              handleSetCep({
                value: value.length > 9 ? value.slice(0, 9) : value,
                setState: setCepEnd,
              })
            }
            value={cepEnd}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <DatePicker
            title="Data Início"
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Final"
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <Dropdown
            required={true}
            title={`Escolha o tipo de promoção`}
            selected={promotionType}
            setSelected={setPromotionType}
            options={promotionTypes}
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          {promotionType === "Porcentagem" && (
            <FormInput
              required={true}
              title={promotionTypes[0].description}
              placeholder="Ex: 20 = 20%"
              setValue={setDiscount}
              type="number"
              value={discount}
              width="250px"
              height="2rem"
              borderRadius="2px"
            />
          )}
          {promotionType === "Desconto de Valor" && (
            <FormInput
              required={true}
              title={promotionTypes[1].description}
              placeholder="Digite o valor aqui"
              setValue={setDiscount}
              type="number"
              value={discount}
              width="250px"
              height="2rem"
              borderRadius="2px"
            />
          )}
          {promotionType === "Valor Fixo" && (
            <FormInput
              required={true}
              title={promotionTypes[2].description}
              placeholder="Digite o valor aqui"
              setValue={setDiscount}
              type="number"
              value={discount}
              width="250px"
              height="2rem"
              borderRadius="2px"
            />
          )}
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleUpdateShippingPromotion}
            disabled={isWaitingApi || !cepStart || !cepEnd || !discount}
          >
            {isWaitingApi ? "Carregando..." : "Salvar"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => navigate(`/promotions/shipping/all`)}
            disabled={isWaitingApi}
            customBackground={theme.colors.wine_red}
          >
            {isWaitingApi ? "Carregando..." : "Voltar"}
          </Button>
        </DivBlock>
      </DivFlex>
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

interface DownloadCSVButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  variant: "dark" | "light";
  customBackground?: string | null;
  disabled?: boolean;
}

export const TemplateButton = styled(CSVLink)<DownloadCSVButtonProps>`
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) =>
    props.customBackground
      ? props.customBackground
      : props.theme.colors.primary};
  border-radius: 0;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) =>
    props.variant === "light"
      ? props.theme.colors.text_light
      : props.theme.colors.text_dark};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }
`;
