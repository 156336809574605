export function convertToBrazilianDateTime(dateString: string): string {
  const date: Date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const brazilianDateTime: string = date.toLocaleDateString("pt-BR", options);
  return brazilianDateTime;
}
