import { ReactElement } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import styled from "styled-components";


export interface ElementListItem {
    id: string,
    component: ReactElement
}

export default function ElementList({itemList, setItemList}: {itemList: Array<ElementListItem>; setItemList: Function}) {
    function handleOnDragEnd(result: DropResult) {
        if (!result.destination) return;
        const items = Array.from(itemList)
        const [newOrder] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, newOrder)

        setItemList(items)
    }

    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="elementList" direction="horizontal">
                {(provided) => (
                    <ElementListUl {...provided.droppableProps} ref={provided.innerRef}>
                        {itemList.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                {(provided) => (
                                    <ElementListLi {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                        {item.component}
                                    </ElementListLi>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </ElementListUl>
                )}
            </Droppable>
        </DragDropContext>
    )
}

const ElementListUl = styled.ul`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 180px;
    width: 100%;
    list-style: none;
    overflow-x: scroll;
`;

const ElementListLi = styled.li`
    
`;