import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import OrderedTable from "../../components/OrderedTable";
import { getAppRevenueReport } from "../../services/reportsService";
import { formatDateIntoDayMonthYear } from "../../utils/formatDateIntoDayMonthYear";

export default function PaymentsReport() {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const date = new Date(new Date().getTime());
    return date.toISOString().split("T")[0];
  });

  const [appRevenueReport, setAppRevenueData] = useState<any>([]);

  async function handleGetAppRevenueReportData() {
    try {
      setIsWaitingApi(true);
      const response = await getAppRevenueReport({
        startDate,
        endDate,
      });

      setAppRevenueData(response);
      setIsWaitingApi(false);
    } catch (error) {
      setIsWaitingApi(false);
      console.log(error);
      toast.error("Erro ao buscar resultados");
    }
  }

  useEffect(() => {
    handleGetAppRevenueReportData();
  }, []);

  return (
    <section className="wrapper">
      <Title>Relatório geral de receita do App</Title>
      <DivFlex>
        <DivBlock>
          <DatePicker
            title="Data Início"
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Final"
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleGetAppRevenueReportData}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Atualizar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <SubTitle>Quantidade de pedidos feitos pelos cliente</SubTitle>
      <OrderedTable
        rows={
          appRevenueReport.paymentReport?.map((row: { [x: string]: any }) => {
            const filteredRow: { [key: string]: any } = {
              Dia: formatDateIntoDayMonthYear(row["Dia"]),
            };

            Object.keys(row).forEach((key) => {
              if (
                !key.includes("Aprovado") &&
                !key.includes("Taxa") &&
                !key.includes("Dia")
              ) {
                filteredRow[key] = row[key];
              }
            });

            return filteredRow;
          }) || []
        }
      />

      <SubTitle>Quantidade de pedidos pagos pelos cliente</SubTitle>
      <OrderedTable
        rows={
          appRevenueReport.paymentReport?.map((row: { [x: string]: any }) => {
            const filteredRow: { [key: string]: any } = {
              Dia: formatDateIntoDayMonthYear(row["Dia"]),
            };

            Object.keys(row).forEach((key) => {
              if (key.includes("Aprovado") && !key.includes("Taxa")) {
                filteredRow[key] = row[key];
              }
            });

            return filteredRow;
          }) || []
        }
      />

      <SubTitle>Formula dividindo pedidos pagos por pedidos feitos</SubTitle>
      <OrderedTable
        rows={
          appRevenueReport.paymentReport?.map((row: { [x: string]: any }) => {
            const filteredRow: { [key: string]: any } = {
              Dia: formatDateIntoDayMonthYear(row["Dia"]),
            };

            Object.keys(row).forEach((key) => {
              if (!key.includes("Aprovado") && key.includes("Taxa")) {
                filteredRow[key] = Number(row[key] || 0).toFixed(2) + "%";
              }
            });

            return filteredRow;
          }) || []
        }
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  text-align: center;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  input {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
