import styled from "styled-components";

export const ContentContainer = styled.div`
  position: absolute;
  width: 80%;
  height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StatusContainer = styled.td`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100px;
`;

export const StatusDescription = styled.p`
  &.active {
    color: #9cd186;
  }

  &.desactive {
    color: red;
  }
`;

export const ContentInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
`;

export const SectionInformationTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  color: #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

export const AddressContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
`;

interface IStatusButton {
  active: boolean;
}

export const StatusButton = styled.button<IStatusButton>`
  cursor: pointer;
  text-transform: none;
  background-color: ${({ active }) =>
    active ? "rgb(176, 13, 31)" : "rgb(156, 209, 134)"};
  color: rgb(255, 255, 255);
  font-family: "Open Sans";
  font-weight: 400;
  border-width: 2px;
  border-color: rgb(156, 209, 134);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 5px 15px;
  box-sizing: border-box;
`;

export const UserEditFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;
