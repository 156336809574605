import styled from "styled-components";
import { FormattedCategory } from "../../services/types";
import CategoryListItem from "./CategoryListItem";

export default function CategoriesListTable({
  categories,
}: {
  categories: FormattedCategory[];
}) {
  return (
    <CategoriesContainer>
      <thead>
        <tr>
          <th>Id</th>
          <th>Nome</th>
          <th>Ordenação</th>
          <th>Ativa</th>
          <th>Editar</th>
          <th>Atualizada em</th>
        </tr>
      </thead>
      <tbody>
        {categories?.map((category) => (
          <CategoryListItem {...category} key={category.id} />
        ))}
      </tbody>
    </CategoriesContainer>
  );
}

const CategoriesContainer = styled.table`
  font-size: 16px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  color: ${(props) => props.theme.colors.text_dark};
  margin: 3rem 0;

  margin: 3rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  padding-top: 3rem;

  tbody,
  thead {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
      background-color: #eee;
      th {
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
        text-align: center;
        padding: 8px;
        border: 1px solid ${(props) => props.theme.colors.text_light};
      }
      td {
        text-align: center;
        padding: 4px;
        display: table-cell;
        vertical-align: middle;
        border: 1px solid ${(props) => props.theme.colors.border};
      }
    }
    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.background_light};
    }
  }

  strong {
    font-size: 1.5rem;
  }
`;
