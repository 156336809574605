import React, { useRef, useState } from "react";
import styled from "styled-components";
import { getCategories } from "../../services/categoriesService";
import { getCollections } from "../../services/collectionService";
import Button from "../../components/Button";
import Dropdown from "../../components/BannerManager/Dropdown";
import ProductSelector from "../../components/BannerManager/ProductSelector";
import { toast } from "react-toastify";
import FormInput from "../../components/BannerManager/FormInput";

export default function GenerateLink() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [resultLink, setResultLink] = useState<string>("");
  const [redirectType, setRedirectType] = useState<string>("");
  const [col, setCol] = useState<string>("");
  const [cat, setCat] = useState<string>("");
  const [prod, setProd] = useState<string>("");
  const [promoCode, setPromoCode] = useState<string>("");
  const [subLocation, setSubLocation] = useState<string>("");

  const bannerTypes = [
    {
      title: "Coleçao",
      value: "colecao",
    },
    {
      title: "Categoria",
      value: "categoria",
    },
    {
      title: "Produto",
      value: "produto",
    },
    {
      title: "Assinatura",
      value: "signature",
    },
  ];

  const generateUrl = (base: string, id: string | null, promoCode: string) => {
    const url = `sonoma://app/${base}?id=${id}`;
    return promoCode.length > 0 ? `${url}&promo=${promoCode}` : url;
  };

  const generateLink = (
    redirectType: string,
    col: string,
    cat: string,
    prod: string
  ) => {
    switch (redirectType) {
      case "colecao":
        return generateUrl("cluster", col, promoCode);
      case "categoria":
        return generateUrl("category", cat, promoCode);
      case "produto":
        return generateUrl("product", prod, promoCode);
      case "signature":
        return generateUrl("signature", null, promoCode);
      default:
        return "";
    }
  };

  const handleGenerateLink = () => {
    const link = generateLink(redirectType, col, cat, prod);
    if (link) {
      toast.success("Link criado com sucesso!");
      setResultLink(link);
      return;
    }

    toast.warning("Seleciona corretamente para gerar o link");
  };

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand("copy");
      toast.success("Texto copiado!");
    }
  };

  return (
    <section className="wrapper">
      <Form>
        <Title>Gerador de Deep Link</Title>
        <Dropdown
          required={true}
          title={`Escolha o tipo de redirect`}
          selected={redirectType}
          setSelected={setRedirectType}
          options={bannerTypes}
        />
        {redirectType === "colecao" && (
          <Dropdown
            required={true}
            title={`Escolha uma coleção ${col ? `Id: ${col}` : ""}`}
            selected={col}
            setSelected={setCol}
            options={async () => await getCollections()}
          />
        )}
        {redirectType === "categoria" && (
          <Dropdown
            required={true}
            title={`Escolha uma categoria ${cat ? `Id: ${cat}` : ""}`}
            selected={cat}
            setSelected={setCat}
            options={async () => await getCategories()}
          />
        )}
        {redirectType === "produto" && (
          <ProductSelector
            initialProd={prod}
            setProd={setProd}
            setFormIsValid={setFormIsValid}
          />
        )}
        {redirectType === "category" && (
          <Dropdown
            required={true}
            title={`Escolha a categoria em que o banner vai aparecer ${
              subLocation ? `Id: ${subLocation}` : ""
            }`}
            selected={subLocation}
            setSelected={setSubLocation}
            options={async () => await getCategories()}
          />
        )}
        <FormInput
          setValue={setPromoCode}
          title="Promo Code (opcional):"
          width="250px"
          height="30"
          borderRadius="10"
          value={promoCode}
        />
        <Button variant="light" onClick={handleGenerateLink}>
          Criar
        </Button>
        {resultLink.length > 0 && (
          <BoxText>
            <Input type="text" ref={inputRef} value={resultLink} />
            <Button variant="light" onClick={handleCopy}>
              Copiar
            </Button>
          </BoxText>
        )}
      </Form>
    </section>
  );
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 10rem;

  strong {
    font-size: 1.5rem;
  }
`;

const Title = styled.h1`
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  margin-top: 20px;
`;

const BoxText = styled.h1`
  display: flex;
  align-content: center;
  justify-content: center;

  margin-top: 10px;
`;

const Input = styled.input`
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 1.2rem;
  padding: 1rem 1rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 300px;
  margin-right: 10px;
`;
