import { useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import {
  getClientsOrdersQuantityReport,
  getClientsReport,
  getOrdersReport,
} from "../../services/reportsService";
import {
  clientReportHeader,
  clientsOrdersQuantityHeader,
  formatOrderData,
  ordersReportHeader,
} from "../../utils/reports";

const Reports = () => {
  const [minDate, setminDate] = useState("");
  const [maxDate, setmaxDate] = useState("");
  const [loading, setLoading] = useState(false);

  const [clientData, setClientData] = useState<any | null>(null);
  const [orderData, setOrderData] = useState<any | null>(null);
  const [clientOrderQtdData, setClientOrderQtdData] = useState<any[] | null>(
    null
  );

  const promises = [
    getClientsReport(minDate, maxDate),
    getOrdersReport(minDate, maxDate),
    getClientsOrdersQuantityReport(),
  ];

  async function fetchData() {
    // clear old data
    setClientData(null);
    setOrderData(null);
    setClientOrderQtdData(null);

    setLoading(true);

    // fetch and store all reports requests
    Promise.all(promises).then((values) => {
      setClientData(values[0]);
      setOrderData(formatOrderData(values[1]));
      setClientOrderQtdData(values[2]);
    });

    setLoading(false);
  }

  return (
    <section className="wrapper">
      <FiltersContainer>
        <LeftFilters>
          <DateContainer>
            <p>Data mínima</p>

            <DatePicker
              type="date"
              value={minDate}
              onChange={(e) => setminDate(e.target.value)}
            />
          </DateContainer>

          <DateContainer>
            <p>Data máxima</p>

            <DatePicker
              type="date"
              value={maxDate}
              onChange={(e) => setmaxDate(e.target.value)}
            />
          </DateContainer>

          <ApplyButton onClick={async () => await fetchData()}>
            Pesquisar
          </ApplyButton>
        </LeftFilters>
      </FiltersContainer>

      <Table>
        <HeaderCell>Clientes criados e com pedidos</HeaderCell>
        <HeaderCell>Pedidos criados, aprovados e valores</HeaderCell>
        <HeaderCell>Quantidade de CLIENTES x PEDIDOS</HeaderCell>
        <Row onClick={() => {}} style={{ cursor: "pointer" }}>
          <Cell>
            {loading || !clientData ? (
              <FakeButton>Download</FakeButton>
            ) : (
              <DownloadOrdersButton
                data={[clientData]}
                headers={clientReportHeader}
                filename={"client_report.csv"}
              >
                Download
              </DownloadOrdersButton>
            )}
          </Cell>
          <Cell>
            {loading || !orderData ? (
              <FakeButton>Download</FakeButton>
            ) : (
              <DownloadOrdersButton
                data={[orderData]}
                headers={ordersReportHeader}
                filename={"order_report.csv"}
              >
                Download
              </DownloadOrdersButton>
            )}
          </Cell>
          <Cell>
            {loading || !clientOrderQtdData ? (
              <FakeButton>Download</FakeButton>
            ) : (
              <DownloadOrdersButton
                data={clientOrderQtdData}
                headers={clientsOrdersQuantityHeader}
                filename={"client_orders_report.csv"}
              >
                Download
              </DownloadOrdersButton>
            )}
          </Cell>
        </Row>
      </Table>

      {loading && <LoadingText>carregando...</LoadingText>}
    </section>
  );
};

export default Reports;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1em;
  padding: 0.5rem;
  align-items: flex-end;
`;

const DateContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DatePicker = styled.input.attrs({
  type: "date",
})``;

const SearchInput = styled.input.attrs({
  placeholder: "Digite um código de pedido",
})`
  flex: 1;
  height: 2.5rem;
  padding: 0.5rem;
`;

const ApplyButton = styled.button`
  width: 12.5rem;
  height: 2.5rem;
`;

const LeftFilters = styled.div`
  display: flex;
  flex: 2;
  justify-content: right;
  align-items: flex-end;
`;

const DownloadOrdersButton = styled(CSVLink)`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
`;

const FakeButton = styled.a`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #c6c6c6;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
`;

const LoadingText = styled.p`
  font-size: 1.5rem;
  margin-top: 1.5rem;
  text-align: center;
`;
