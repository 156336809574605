import axios from "axios";
import configs from "../appConfigs";
import formatCategoriesRecursively from "../utils/formatCategoriesRecursively";
import { AppCategory, FormattedCategory } from "./types";

export async function getCategories() {
  const categories = await axios.get(
    `${configs.appApiBaseUrl}/vitrines/101/categorias/all`
  );

  return formatCategoriesRecursively(categories.data);
}

export async function getCategoriesWithDetails() {
  const categories = await axios.get(
    `${configs.appApiBaseUrl}/vitrines/101/categorias/admin/all`
  );

  return categories?.data as FormattedCategory[];
}

export async function getImmediateSubCategories({
  parentCategoryId,
}: {
  parentCategoryId: number;
}) {
  const immediateSubCategories = await axios.get(
    `${configs.appApiBaseUrl}/vitrines/101/categorias/${parentCategoryId}/immediate-subcategories`
  );

  return immediateSubCategories.data.data.map(
    (immediateSubCategory: AppCategory) => ({
      title: `${immediateSubCategory.vic_st_descricao
        .charAt(0)
        .toUpperCase()}${immediateSubCategory.vic_st_descricao
        .slice(1)
        .toLocaleLowerCase()}`,
      value: immediateSubCategory.vic_in_codigo,
    })
  );
}

export async function postCategoryOrderBy({
  categoryId,
  orderBy,
}: {
  categoryId: number | null;
  orderBy: string | null;
}) {
  if (!categoryId || !orderBy) return;

  await axios.post(`${configs.apiBaseUrl}/api/category/orderby`, {
    categoryId,
    orderBy,
  });
}
