import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import OrderedTable from "../../components/OrderedTable";
import {
  deleteRestriction,
  getRestrictions,
} from "../../services/restrictionsService";
import { FormattedRestriction, Restriction } from "../../services/types";
import ProductRestrictionsListButton from "./ProductRestrictionsListButton";

const _mountRestrictionsRows = ({
  restrictionsList,
  handleDelete,
  handleEdit,
}: {
  restrictionsList: Restriction[];
  handleDelete: ({ id }: { id: string }) => Promise<void>;
  handleEdit: ({ id }: { id: string }) => Promise<void>;
}) => {
  return restrictionsList.map(
    (item: {
      ren_st_descricao: string;
      ren_in_inicio_cep: string;
      ren_in_final_cep: string;
      ren_in_codigo: number;
      products: number;
    }) => ({
      "Cidade ou Região": item.ren_st_descricao,
      "Inicio da Faixa do CEP": item.ren_in_inicio_cep
        .toString()
        .replace(/(\d{5})(?=\d)/g, "$1-"),
      "Fim da Faixa do CEP": item.ren_in_final_cep
        .toString()
        .replace(/(\d{5})(?=\d)/g, "$1-"),
      "Nº Produtos Restritos": item.products,
      "": (
        <>
          <ProductRestrictionsListButton
            id={item.ren_in_codigo.toString()}
            action={handleEdit}
            type="edit"
          />
          <ProductRestrictionsListButton
            id={item.ren_in_codigo.toString()}
            action={handleDelete}
            type="delete"
          />
        </>
      ),
    })
  );
};

export default function ProductRestrictions() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const [restrictions, setRestrictions] = useState(
    [] as FormattedRestriction[]
  );

  const handleEdit = async ({ id }: { id: string }) => {
    navigate(`/restriction/${id}`);
  };

  const handleDelete = async ({ id }: { id: string }) => {
    if (
      !window.confirm(`
          Atenção:
          Você está prestes a deletar uma regionalização. Esta ação é irreversível!
          Pressione "Ok" para confirmar a exclusão.`)
    )
      return;

    setIsWaitingApi(true);
    const res = await deleteRestriction({ id });
    if (res?.status && res.status < 400)
      toast.success(`Regionalização excluida com sucesso!`, {
        pauseOnFocusLoss: false,
      });
    setIsWaitingApi(false);
    handleGetRestrictions();
  };

  async function handleGetRestrictions() {
    setIsWaitingApi(true);
    const response = await getRestrictions();

    if (response)
      setRestrictions(
        _mountRestrictionsRows({
          restrictionsList: response,
          handleDelete,
          handleEdit,
        })
      );

    setIsWaitingApi(false);
  }

  useEffect(() => {
    handleGetRestrictions();
  }, []);

  return (
    <section className="wrapper">
      <Title>Regionalização de produtos</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title=""
            setValue={setSearch}
            value={search}
            placeholder="Busca"
            width="500px"
            height="2.5rem"
            borderRadius="8px"
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => navigate(`/restriction`)}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Criar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <OrderedTable
        rows={restrictions.filter((item) => {
          if (search) {
            return item["Cidade ou Região"].includes(search);
          }
          return true;
        })}
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
