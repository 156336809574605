import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export default function MenuItem({ to, title }: { to: string; title: string; }) {
  return (
    <Link to={to}>{title}</Link>
  )
}

const Link = styled(NavLink)`
  color: ${props => props.theme.colors.text_dark};
  text-decoration: none;
  padding: 1rem 1.5rem .5rem;
  font-size: 1.2rem;
  border-bottom: 1px solid transparent;
  &.active {
    color: ${props => props.theme.colors.primary};
    border-bottom: 1px solid ${props => props.theme.colors.primary};
  }
`;