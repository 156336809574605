import styled from "styled-components";

export const Section = styled.section`
  margin: 0 auto;
  width: 1200px;
  height: 1400px;
  padding: 0 1rem;
`;

export const SectionInvoicing = styled.section`
  margin: 0 auto;
  max-width: 1200px;
  height: max-content;
  margin-bottom: 10px;
  padding: 0 1rem;
`;

export const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.6rem;
  font-weight: 700;
  margin-top: 3rem;
`;

export const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

export const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

export const List = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const CardItem = styled.div`
  width: 20rem;
  height: 38rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
`;

export const Img = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const Text = styled.p`
  margin: 4px;
  font-size: 1rem;
`;

export const DivButtons = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const FormCreate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const DivFlexForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;
