import React, { useState } from "react";
import { Form, Main, SubTitle, Title } from "./style";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { createSeller } from "../../services/sellerCenterService";

const SellerCenter: React.FC = () => {
  const [sellerName, setsellerName] = useState<string>("");
  const [sellerEmail, setSellerEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCreateSeller = async () => {
    const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!regex.test(sellerEmail)) {
      setError(true);
      toast.error("Insira um e-mail válido");
      return;
    }
    setLoading(true);

    try {
      await createSeller({
        name: sellerName,
        email: sellerEmail,
      });
    } catch (error: any) {
      setLoading(false);
    }

    setLoading(false);
    setError(false);
  };
  return (
    <Main>
      <Title>Criação de Seller</Title>
      <Form>
        <FormInput
          title="Nome do Seller"
          placeholder="Digite um nome aqui"
          setValue={setsellerName}
          type="text"
          value={sellerName}
          required
          width="300px"
          height="2rem"
          borderRadius="2px"
        />
        <FormInput
          title="Email do Seller"
          placeholder="Digite um nome aqui"
          setValue={setSellerEmail}
          type="email"
          value={sellerEmail}
          required
          width="300px"
          height="2rem"
          borderRadius="2px"
        />
        {error && (
          <SubTitle style={{ color: "red", fontSize: 14 }}>
            Insira um email válido, Ex: seller@gmail.com
          </SubTitle>
        )}
        <Button
          variant="light"
          onClick={handleCreateSeller}
          disabled={!sellerEmail || !sellerName}
        >
          {loading ? "Criando seller..." : "Criar seller"}
        </Button>
      </Form>
    </Main>
  );
};

export default SellerCenter;
