import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import OrderedTable from "../../components/OrderedTable";
import {
  deleteShippingPromotion,
  getShippingPromotions,
} from "../../services/shippingPromotionsService";
import ProductRestrictionsListButton from "../ProductRestrictions/ProductRestrictionsListButton";

const _mountPromotionsRows = ({
  promotionList,
  handleDelete,
  handleEdit,
}: {
  promotionList: [];
  handleDelete: ({ id }: { id: string }) => Promise<void>;
  handleEdit: ({ id }: { id: string }) => Promise<void>;
}) => {
  return promotionList.map(
    (item: {
      shp_dt_endDate: string;
      shp_dt_startDate: string;
      shp_in_amount: number;
      shp_in_codigo: number;
      shp_in_final_cep: number;
      shp_in_inicio_cep: number;
      shp_st_name: string;
      shp_st_type: string;
    }) => ({
      Nome: item.shp_st_name,
      De: item.shp_in_inicio_cep.toString().replace(/(\d{5})(?=\d)/g, "$1-"),
      Até: item.shp_in_final_cep.toString().replace(/(\d{5})(?=\d)/g, "$1-"),
      Tipo: item.shp_st_type,
      Desconto: item.shp_in_amount,
      Editar: (
        <ProductRestrictionsListButton
          id={item.shp_in_codigo.toString()}
          action={handleEdit}
          type="edit"
        />
      ),
      Excluir: (
        <ProductRestrictionsListButton
          id={item.shp_in_codigo.toString()}
          action={handleDelete}
          type="delete"
        />
      ),
    })
  );
};

export default function ShippingPromotions() {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const [promotions, setPromotions] = useState([] as any);

  const handleEdit = async ({ id }: { id: string }) => {
    navigate(`/promotions/shipping/${id}`);
  };

  const handleDelete = async ({ id }: { id: string }) => {
    if (
      !window.confirm(`
          Atenção:
          Você está prestes a deletar uma promoção. Esta ação é irreversível!
          Pressione "Ok" para confirmar a exclusão.`)
    )
      return;

    setIsWaitingApi(true);
    const res = await deleteShippingPromotion({ id });
    if (res?.status && res.status < 400)
      toast.success(`Promoção excluida com sucesso!`, {
        pauseOnFocusLoss: false,
      });
    setIsWaitingApi(false);
    handleGetShippingPromotions();
  };

  async function handleGetShippingPromotions() {
    setIsWaitingApi(true);
    const response = await getShippingPromotions();

    if (response)
      setPromotions(
        _mountPromotionsRows({
          promotionList: response,
          handleDelete,
          handleEdit,
        })
      );

    setIsWaitingApi(false);
  }

  useEffect(() => {
    handleGetShippingPromotions();
  }, []);

  return (
    <section className="wrapper">
      <Title>Promoções de frete</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Busca"
            setValue={setSearch}
            value={search}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => navigate(`/promotions/shipping`)}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Criar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <OrderedTable
        rows={promotions.filter((item: { Nome: string }) => {
          if (search) {
            return item.Nome.toLowerCase().includes(search.toLowerCase());
          }
          return true;
        })}
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
