import axios from "axios";
import configs from "../appConfigs";

interface IPutUserStatus {
  userId: number;
  status: boolean;
}

export async function putUserStatus({ userId, status }: IPutUserStatus) {
  const { data } = await axios.put(
    `${configs.apiBaseUrl}/admin/user/${userId}/status/${status}`
  );

  return data;
}
