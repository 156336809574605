export function getSumOfSignaturesOfDay(obj: {
  [key: string]: number | string;
}): number {
  let sum = 0;

  for (const key in obj) {
    if (key !== "date") {
      sum += Number(obj[key]);
    }
  }

  return sum;
}
