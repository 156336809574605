import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Dropdown from "../../components/BannerManager/Dropdown";
import Button from "../../components/Button";
import {
  getImmediateSubCategories,
  postCategoryOrderBy,
} from "../../services/categoriesService";

const filtersOptions = [
  {
    title: "A>Z",
    value: "A>Z",
  },
  {
    title: "Z>A",
    value: "Z>A",
  },
  {
    title: "Menor Preço",
    value: "Menor Preço",
  },
  {
    title: "Maior Preço",
    value: "Maior Preço",
  },
  {
    title: "Data de Lançamento",
    value: "Data de Lançamento",
  },
];

export default function CategoriesOrderBy() {
  const { id } = useParams();
  const location = useLocation() as { state: { categoryName: string } };
  const navigate = useNavigate();
  const [departmentId, setDepartmentId] = useState(Number(id) || null);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [orderBy, setOrderBy] = useState(null);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);

  async function handleUpdateCategoryOrderBy(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();

    try {
      await postCategoryOrderBy({
        categoryId: subCategoryId || categoryId || departmentId,
        orderBy,
      });

      toast.success(`OrderBy atualizado com sucesso!`, {
        pauseOnFocusLoss: false,
        onClose: () => {
          navigate("/categories");
        },
      });
    } catch (error) {
      toast.error(
        `Erro ao tentar atualizar orderBy da categoria \n", ${error}`
      );
    }
  }

  useEffect(() => {
    if (id) return;

    setCategoryId(null);
    setCategoryOptions([]);
    departmentId &&
      getImmediateSubCategories({
        parentCategoryId: departmentId,
      }).then((res) => {
        setCategoryOptions(res);
      });
  }, [departmentId, id]);

  useEffect(() => {
    setSubCategoryId(null);
    setSubCategoryOptions([]);
    categoryId &&
      getImmediateSubCategories({
        parentCategoryId: categoryId,
      }).then((res) => {
        setSubCategoryOptions(res);
      });
  }, [categoryId]);

  return (
    <section className="wrapper">
      <Title>
        {location?.state?.categoryName
          ? `Ordenando ${location.state.categoryName} Id: ${id}`
          : "Ordenação de categorias"}
      </Title>
      <CategoriesOrderByContainer onSubmit={handleUpdateCategoryOrderBy}>
        {!id && (
          <InputContainer>
            <Dropdown
              required={true}
              title={`Escolha um departamento ${
                departmentId ? `Id: ${departmentId}` : ""
              }`}
              selected={departmentId}
              setSelected={setDepartmentId}
              options={async () =>
                await getImmediateSubCategories({ parentCategoryId: 101 })
              }
            />

            {!!categoryOptions.length && (
              <Dropdown
                title={`Escolha uma categoria ${
                  categoryId ? `Id: ${categoryId}` : ""
                }`}
                selected={categoryId}
                setSelected={setCategoryId}
                options={categoryOptions}
              />
            )}

            {!!subCategoryOptions.length && (
              <Dropdown
                title={`Escolha uma subcategoria ${
                  subCategoryId ? `Id: ${subCategoryId}` : ""
                }`}
                selected={subCategoryId}
                setSelected={setSubCategoryId}
                options={subCategoryOptions}
              />
            )}
          </InputContainer>
        )}
        <InputContainer>
          <Dropdown
            required={true}
            title={`Escolha uma ordenação`}
            selected={orderBy}
            setSelected={setOrderBy}
            options={filtersOptions}
          />
          <Button variant="light">Enviar</Button>
        </InputContainer>
      </CategoriesOrderByContainer>
    </section>
  );
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const CategoriesOrderByContainer = styled.form`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25%;

  margin: 3rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  padding-top: 3rem;

  strong {
    font-size: 1.5rem;
  }
`;

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;
