import styled from "styled-components";
import { OrderT } from "../../../services/types";
import { formatDate } from "../../../utils/data";
import getPaymentDescription from "../../../utils/paymentTypes";

export default function Header({ order }: { order: OrderT }) {
  return (
    <Table>
      <thead>
        <Row>
          <HeaderCell>Codigo pedido</HeaderCell>
          <HeaderCell>Data pedido</HeaderCell>
          <HeaderCell>Pagamento</HeaderCell>
          <HeaderCell>Status</HeaderCell>
        </Row>
      </thead>
      <tbody>
        <tr>
          <Cell>{order.codPedido}</Cell>
          <Cell>{formatDate(order.createdAt)}</Cell>
          <Cell>{getPaymentDescription(order.tipoPagamento)}</Cell>
          <Cell>{order.status}</Cell>
        </tr>
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  margin-bottom: 2rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;
