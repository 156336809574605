import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/Button";
import OrderedTable from "../../components/OrderedTable";
import { getAppClientsReport } from "../../services/reportsService";

export default function ClientsReport() {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const [appClientsReport, setAppRevenueData] = useState<any>([]);

  async function handleGetAppClientsReportData() {
    try {
      setIsWaitingApi(true);
      const response = await getAppClientsReport();

      setAppRevenueData(response);
      setIsWaitingApi(false);
    } catch (error) {
      setIsWaitingApi(false);
      console.log(error);
      toast.error("Erro ao buscar resultados");
    }
  }

  useEffect(() => {
    handleGetAppClientsReportData();
  }, []);

  const { "Quantidade de clientes por Estado": _, ...rest } =
    appClientsReport?.clientsReport || {};

  return (
    <section className="wrapper">
      <Title>Relatório de Clientes do App (visão somada)</Title>
      <DivFlex>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleGetAppClientsReportData}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Atualizar"}
          </Button>
        </DivBlock>
      </DivFlex>

      <OrderedTable
        rows={[
          {
            Campo: "Quantidade de clientes cadastrados",
            Quantidade:
              appClientsReport?.clientsReport?.[
                "Quantidade de clientes cadastrados"
              ],
          },
          {
            Campo:
              "Quantidade de clientes cadastrados com endereço e sem cartão salvo",
            Quantidade:
              appClientsReport?.clientsReport?.[
                "Quantidade de clientes cadastrados com endereço e sem cartão salvo"
              ],
          },
          {
            Campo: "Quantidade de clientes cadastrados sem endereço",
            Quantidade:
              appClientsReport?.clientsReport?.[
                "Quantidade de clientes cadastrados sem endereço"
              ],
          },
          {
            Campo: "Quantidade de clientes cadastrados com endereço",
            Quantidade:
              appClientsReport?.clientsReport?.[
                "Quantidade de clientes cadastrados com endereço"
              ],
          },
          {
            Campo: "Quantidade de clientes cadastrados sem pedidos",
            Quantidade:
              appClientsReport?.clientsReport?.[
                "Quantidade de clientes cadastrados sem pedidos"
              ],
          },
        ]}
      />
      <SubTitle>Quantidade de clientes por Estado (UF)</SubTitle>
      <OrderedTable
        rows={
          appClientsReport?.clientsReport?.[
            "Quantidade de clientes por Estado"
          ]?.map((row: { [x: string]: any }) => ({
            Estado: Object.keys(row)[0],
            Quantidade: row[Object.keys(row)[0]],
            "Taxa de participação": `${(
              (row[Object.keys(row)[0]] /
                appClientsReport?.clientsReport?.[
                  "Quantidade de clientes cadastrados com endereço"
                ] || 1) * 100
            ).toFixed(2)}%`,
          })) || []
        }
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  text-align: center;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  input {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
