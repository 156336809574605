import axios, { AxiosPromise } from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";
import { BannerLocationOptions } from "../components/BannerManager";
import { getCategories } from "./categoriesService";
import { getCollections } from "./collectionService";
import { Banners } from "./types";

export async function getBanners() {
  try {
    const banners: AxiosPromise<{ banners: Banners }> = axios.get(
      `${configs.apiBaseUrl}/api/listBannersAdmin`
    );
    return banners;
  } catch (error) {
    toast.error(`Erro ao tentar recuperar banners da API \n", ${error}`);
  }
}

export async function putBannersOrder({
  bannersOrdered,
}: {
  bannersOrdered: { banner_id: number; position: number }[];
}) {
  try {
    const res = axios.put(`${configs.apiBaseUrl}/api/reorderBanners`, {
      bannersOrdered,
    });
    toast.success("banner editado com sucesso!");

    return res;
  } catch (error) {
    toast.error(`Erro ao tentar atualizar banners na API \n", ${error}`);
  }
}

export async function postCreateBanner({ formData }: { formData: FormData }) {
  try {
    const response = await axios.postForm(
      `${configs.apiBaseUrl}/api/createBanner`,
      formData
    );
    toast.success("banner criado com sucesso!");
    return response;
  } catch (error) {
    const errorImageSize = (error as any)?.response?.data?.includes(
      "File too large"
    );

    if (errorImageSize)
      toast.error(`Erro ao tentar criar banner, tamanho de imagem excedido!`);
    else toast.error(`Erro ao tentar criar banner, ${error}`);
  }
}

export async function deleteBanner({ banner_id }: { banner_id: number }) {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/deleteBanner`, { banner_id });
    toast.success("banner deletado com sucesso!");
  } catch (error) {
    toast.error(`Erro ao tentar deletar banner \n", ${error}`);
  }
}

export async function getSubLocationTitle({
  location,
  id,
}: {
  location: BannerLocationOptions["location"];
  id: string;
}) {
  if (location === "category") {
    const categories = await getCategories();

    return categories.filter((category) => category.value === id)[0].title;
  }

  if (location === "cluster") {
    const collections = await getCollections();
    return collections.filter(
      (collection) => collection.value.toString() === id
    )[0].title;
  }

  return "";
}

export async function putUpdateBanner({
  banner_id,
  formData,
}: {
  banner_id: number;
  formData: FormData;
}) {
  try {
    const response = await axios.putForm(
      `${configs.apiBaseUrl}/api/editBanner/${banner_id}`,
      formData
    );
    toast.success("banner editado com sucesso!");
    return response;
  } catch (error) {
    toast.error(`Erro ao editar banner \n", ${error}`);
  }
}
