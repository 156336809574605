import { useEffect, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthContext from "../../auth/authProvider";

export default function Authenticator() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { auth, authHandler } = useContext(AuthContext);
  const googleOauthCode = searchParams.get("code");

  async function authenticate() {
    try {
      if (googleOauthCode && !auth?.refresh_token) {
        await authHandler
          .login({ googleOauthCode })
          .then((_response: any) => navigate("/banners"));
        return;
      }
      navigate("/banners");
    } catch (error) {
      console.error(error, "Error authenticating user");
    }
  }

  useEffect(() => {
    authenticate();
    // eslint-disable-next-line
  }, []);

  return null;
}
