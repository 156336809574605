import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CupomT } from "../../services/types";
import styled from "styled-components";
import Button from "../../components/Button";
import { getAllCoupons } from "../../services/couponService";

export default function CoupomList() {
  const [coupons, setCoupons] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getCoupons = async () => {
    setLoading(true);
    const coupons = await getAllCoupons();
    setCoupons(coupons);
    setLoading(false);
    return coupons;
  };

  const navigator = useNavigate();

  const emailsCX = [
    "rafaela.nascimento@sonoma.com.br",
    "cleciane.oliveira@sonoma.com.br",
    "weslei.andrade@sonoma.com.br",
    "rarime.oliveira@sonoma.com.br",
    "giovanna.silva@sonoma.com.br",
    "isabela.lima@sonoma.com.br",
    "thiago.oliveira@sonoma.com.br",
  ];

  const storedEmail = localStorage.getItem("Email");

  useEffect(() => {
    getCoupons();
  }, []);

  if (loading) {
    return (
      <section className="wrapper">
        <div>carregando...</div>
      </section>
    );
  }

  return (
    <section className="wrapper">
      {coupons?.length > 0 ? (
        <>
          {storedEmail && !emailsCX.includes(storedEmail) && (
            <Row>
              <Button
                style={{ marginRight: 10 }}
                variant={"dark"}
                onClick={() => navigator("/coupons")}
              >
                Criar Cupom
              </Button>
            </Row>
          )}

          <Table>
            <HeaderCell>Nome do Cupom</HeaderCell>
            <HeaderCell>Valor do Cupom</HeaderCell>
            <HeaderCell>Porcentagem do Cupom</HeaderCell>
            <HeaderCell>valor Mínimo</HeaderCell>
            <HeaderCell>Cupom Ativo</HeaderCell>
            <HeaderCell>Uso Máximo</HeaderCell>
            <HeaderCell>Frete Gratis</HeaderCell>
            <HeaderCell>Ativo</HeaderCell>
            <HeaderCell>Data Início</HeaderCell>
            <HeaderCell>Data Final</HeaderCell>
            {coupons?.map((coupons: CupomT) => {
              return (
                <Row>
                  <Cell>{coupons.cup_st_codigo}</Cell>
                  <Cell>{parseInt(coupons.cup_de_valor)}</Cell>
                  <Cell>{coupons.cup_de_porcentagem}%</Cell>
                  <Cell>{parseInt(coupons.cup_de_minvalue || "0")}</Cell>
                  <Cell>{coupons.cup_bo_ativo ? "Sim" : "Não"}</Cell>
                  <Cell>
                    {coupons.cup_in_usomaximo
                      ? coupons.cup_in_usomaximo
                      : "Ilimitado"}
                  </Cell>
                  <Cell>{coupons.cup_bo_fretegratis ? "Sim" : "Não"}</Cell>
                  <Cell>{coupons.cup_bo_ativo ? "Sim" : "Não"}</Cell>

                  <Cell>
                    {coupons.cup_dt_startDate
                      ? new Date(coupons.cup_dt_startDate)
                          .toISOString()
                          .substring(0, 10)
                      : "N/A"}
                  </Cell>
                  <Cell>
                    {coupons.cup_dt_endDate
                      ? new Date(coupons.cup_dt_endDate)
                          .toISOString()
                          .substring(0, 10)
                      : "N/A"}
                  </Cell>
                  <Cell>
                    {" "}
                    {storedEmail && !emailsCX.includes(storedEmail) && (
                      <Button
                        onClick={() =>
                          navigator(`/editCoupons/${coupons.cup_in_codigo}`, {
                            state: coupons,
                          })
                        }
                        variant={"dark"}
                      >
                        Editar
                      </Button>
                    )}{" "}
                  </Cell>
                </Row>
              );
            })}
          </Table>
        </>
      ) : (
        <div>nenhum produto encontrado</div>
      )}
    </section>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;
