import styled from "styled-components";

interface DatePickerProps extends React.HTMLAttributes<HTMLInputElement> {
  title?: string;
  value: string;
}

export default function DatePicker({ title, ...props }: DatePickerProps) {
  return (
    <>
      {title && <Label htmlFor="">{title}</Label>}
      <DatePickerInput {...props} />
    </>
  );
}

const DatePickerInput = styled.input.attrs({
  type: "date",
})`
  color: ${(props) => props.theme.colors.text_dark};
  width: 250px;
  height: 2rem;
  border: 1px solid ${(props) => props.theme.colors.border};
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
  padding: 0.25rem;
  border-radius: 2px;
`;

const Label = styled.label`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
`;
