import {
  ButtonDescriptionContainer,
  Container,
  DataPickerContainer,
  DataSelectButton,
  ProductsDescriptionContainer,
} from "./clientCarts.style";
import { DSTable, DSModal } from "../../utils/DesignSystem.d";
import { FaWindowClose } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getClientCarts } from "../../services/getClientCartsService";
import {
  FetchParams,
  IDateRangePicker,
  IDSProductsTableProps,
  IMetadata,
} from "./types";
import { DateRangePicker } from "../../components/DateRangePicker";
import { format } from "date-fns";

export default function ClientCards() {
  const [refValue, setRefValue] = useState<string>("");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [clientCarts, setClientCarts] = useState<any>([]);
  const [metadata, setMetadata] = useState<IMetadata>({
    paging: {
      limit: 15,
      page: 1,
      total: 0,
    },
    filter: {
      ref: "",
    },
  });

  useEffect(() => {
    async function fetchCarts() {
      const data = await getClientCarts({
        limit: 15,
        ref: "",
        page: 1,
      });

      const filteredCarts = data?.carts.map((cart: any) => {
        return {
          ...cart,
          ...cart.cliente,
        };
      });

      setMetadata(data?._metadata);
      setClientCarts(filteredCarts);
    }

    fetchCarts();
  }, []);

  function handleFetchAndSetCarts(fetchParams: FetchParams) {
    getClientCarts(fetchParams).then((data) => {
      const filteredCarts = data?.carts.map((cart: any) => ({
        ...cart,
        ...cart.cliente,
      }));

      setMetadata(data?._metadata);
      setClientCarts(filteredCarts);
    });
  }

  function HandleChangePage(page: number) {
    handleFetchAndSetCarts({
      page,
      limit: Number(metadata.paging.limit),
      ref: metadata.filter.ref,
      startDate: metadata.filter.startDate!,
      endDate: metadata.filter.endDate!,
    });
  }

  function HandleOnSearching(ref: string) {
    setRefValue(ref);
    handleFetchAndSetCarts({
      page: 1,
      limit: 15,
      ref,
      startDate: metadata.filter.startDate!,
      endDate: metadata.filter.endDate!,
    });
  }

  function HandleOnChangeDate({
    selection: { endDate, startDate },
  }: IDateRangePicker) {
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    setMetadata((oldValue) => ({
      ...oldValue,
      filter: {
        ...oldValue.filter,
        startDate: startDateString,
        endDate: endDateString,
      },
    }));

    handleFetchAndSetCarts({
      page: Number(metadata.paging.page),
      limit: 15,
      ref: metadata.filter.ref,
      startDate: startDateString,
      endDate: endDateString,
    });
  }

  function HandleDataSelectButton() {
    return (
      <DataSelectButton onClick={() => setShowCalendar((current) => !current)}>
        <ButtonDescriptionContainer>
          {metadata.filter.startDate && metadata.filter.endDate
            ? `${format(
                new Date(metadata.filter.startDate),
                "dd/MM/yy"
              )} - ${format(new Date(metadata.filter.endDate), "dd/MM/yy")}`
            : "Selecionar uma data"}
        </ButtonDescriptionContainer>
        {showCalendar && (
          <DataPickerContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                setShowCalendar((current) => !current);
              }}
              aria-hidden
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
            >
              <FaWindowClose color="red" />
            </span>
            <ButtonDescriptionContainer
              className="bgc"
              onClick={() => {
                setShowCalendar(false);
                handleFetchAndSetCarts({
                  page: 1,
                  limit: 15,
                  ref: metadata.filter.ref,
                  startDate: undefined,
                  endDate: undefined,
                });
              }}
            >
              Limpar Data
            </ButtonDescriptionContainer>

            <DateRangePicker
              onChange={(props) => HandleOnChangeDate(props)}
              startDate={metadata.filter.startDate || new Date().toISOString()}
              endDate={metadata.filter.endDate || new Date().toISOString()}
            />
          </DataPickerContainer>
        )}{" "}
      </DataSelectButton>
    );
  }

  function HandleDetailsColumn(props: IDSProductsTableProps) {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const clientCartProduct = props.item.clientcartproduct.map((cart) => cart);
    const subtotal = props.item.clientcartproduct.reduce((acc, curr) => {
      return (
        acc + Number(curr.produto.pro_de_precopor) * Number(curr.ccp_in_qtd)
      );
    }, 0);

    return (
      <td>
        <DSModal
          modalId={props.column.id}
          setOpen={() => {
            setOpenModal((prev) => !prev);
          }}
          open={openModal}
          showButton={false}
        >
          <>
            {clientCartProduct.map((ccp, index) => (
              <ProductsDescriptionContainer>
                <p style={{ color: "gray" }}>Produto: {index + 1}</p>
                <p>
                  <b>Nome: </b> {ccp.produto.pro_st_nomesite}
                </p>
                <p>
                  <b>Quantidade: </b>
                  {ccp.ccp_in_qtd}
                </p>
                <p>
                  <b>Preço: </b>R${" "}
                  {Number(ccp.produto.pro_de_precopor).toFixed(2)}
                </p>
              </ProductsDescriptionContainer>
            ))}
            <p>
              <h2>
                Subotal:{" "}
                <span style={{ color: "#85b074" }}>
                  R$ {subtotal.toFixed(2)}
                </span>
              </h2>
            </p>
          </>
        </DSModal>
        <ButtonDescriptionContainer onClick={() => setOpenModal(true)}>
          <p style={{ textAlign: "center" }}>Detalhes</p>
        </ButtonDescriptionContainer>
      </td>
    );
  }

  function DateColumn({ date }: { date: string }) {
    return (
      <td>
        <p style={{ textAlign: "center" }}>
          {format(new Date(date), "dd/MM/yy")}
        </p>
      </td>
    );
  }

  function HandleCreatedAtColumn(props: IDSProductsTableProps) {
    return <DateColumn date={props.item.createdAt} />;
  }

  function HandleUpdatedAtColumn(props: IDSProductsTableProps) {
    return <DateColumn date={props.item.updatedAt} />;
  }

  return (
    <Container>
      <DSTable
        rows={clientCarts}
        columns={[
          { id: "cca_in_codigo", title: "ID Carrinho" },
          { id: "cli_st_nome", title: "Nome Cliente" },
          { id: "cli_st_email", title: "Email Cliente" },
          {
            id: "createdAt",
            title: "Criado em",
            component: (props) => <HandleCreatedAtColumn {...props} />,
          },
          {
            id: "updatedAt",
            title: "Ultima Modificação",
            component: (props) => <HandleUpdatedAtColumn {...props} />,
          },
          {
            id: "action",
            title: "Detalhes",
            component: (props) => <HandleDetailsColumn {...props} />,
          },
        ]}
        paging={{
          limit: Number(metadata.paging.limit),
          page: Number(metadata.paging.page),
          total: Number(metadata.paging.total),
        }}
        onChangePage={(page) => HandleChangePage(page)}
        onSearch={(ref) => HandleOnSearching(ref)}
        searchPlaceholder={"Pesquisar por nome ou e-mail"}
        refValue={refValue}
        headerTitle="Listagem de carrinhos abandonados"
        headerComponent={() => <HandleDataSelectButton />}
      />
    </Container>
  );
}
