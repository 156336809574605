import { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import { getProductById, getProducts } from "../../services/productsService";
import { formatedProduct } from "../../utils/formatProductsFromApiToListItem";
import Button from "../Button";
import FormInput from "../BannerManager/FormInput";

export default function ProductSelectorCluster({
  initialProd,
  setProd,
  setFormIsValid,
}: {
  initialProd: string;
  setProd: Dispatch<SetStateAction<string>>;
  setFormIsValid: Dispatch<SetStateAction<boolean>>;
}) {
  const [productList, setProductList] = useState<formatedProduct>(
    {} as formatedProduct
  );
  const [prodSearch, setProdSearch] = useState<string>("");
  const [loading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setFormIsValid(false);
  }, []);

  async function handleProductSearch() {
    setIsLoading(true);
    setProductList({} as formatedProduct);
    try {
      const product = await getProducts({ sku: prodSearch });
      setProductList(product);
      setProd(product.productId);
      if (product.productId) setFormIsValid(true);
    } catch (error) {}

    setIsLoading(false);
  }

  const handleKeyPress = (e: { key: string }) => {
    if (e.key === "Enter") {
      handleProductSearch();
    }
  };

  useEffect(() => {
    async function fetchProduct() {
      setProductList(await getProductById({ id: initialProd }));
      setProd(productList.productId);
    }

    if (initialProd) fetchProduct();
  }, []);

  return (
    <>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Digite o SKU do produto1"
            placeholder="Digite um SKU aqui"
            setValue={setProdSearch}
            type="text"
            value={prodSearch}
            onKeyDown={handleKeyPress}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <Button
          variant="light"
          onClick={handleProductSearch}
          disabled={loading || !prodSearch.length}
        >
          Buscar
        </Button>
      </DivFlex>
      <Product product={productList} />
    </>
  );
}

function Product({ product }: { product: formatedProduct }) {
  return (
    <>
      {product.nome ? (
        <ProductView>
          <ProductImage src={product.imageUrl} alt={product.nome} />
          <div>
            <p>Nome: {product.nome}</p>
            <p>ProductId: {product.productId}</p>
          </div>
        </ProductView>
      ) : null}
    </>
  );
}

const DivFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  button {
    margin-bottom: 0.5rem;
    margin-left: 1rem;
  }
`;

const DivBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ProductView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  font-size: 1rem;

  button {
    margin-top: 0.5rem;
  }
`;

export const ProductImage = styled.img`
  max-width: 84px;
  max-height: 84px;
  display: block;
  object-fit: contain;
`;
