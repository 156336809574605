import { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FormFilePicker from "../../components/BannerManager/FormFilePicker";
import FormInput from "../../components/BannerManager/FormInput";
import FormTextArea from "../../components/BannerManager/FormTextArea";
import Button from "../../components/Button";
import Papa from "papaparse";
import { TemplateButton } from "../ProductRestrictions/RestrictionItem";
import { mockData, mockHeaders } from "./mockData";
import OrderedTable from "../../components/OrderedTable";
import { toast } from "react-toastify";
import { getKit, updateKit } from "../../services/kitsService";
import { useNavigate, useParams } from "react-router-dom";
import getImageDownloadUrlFromFireBase from "../../utils/getImageDownloadUrlFromFireBase";
import { formatNumberToBRL } from "../../utils/formatNumberToBRL";
import ProductFinderHelper from "./ProductFinderHelper";
import FormCheckBox from "../../components/FormCheckBox";

export default function EditKit() {
  const { id } = useParams();

  const [kitName, setkitName] = useState<string>("");
  const [kitDescription, setkitDescription] = useState<string>("");
  const [kitAbout, setkitAbout] = useState<string>("");
  const [kitHistory, setkitHistory] = useState<string>("");
  const [preview, setPreview] = useState<string>();
  const [selectedFile, setSelectedFile] = useState<File>();
  const [kitProducts, setkitProducts] = useState<any>();
  const [newProducts, setNewProducts] = useState<any>();
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(true);

  const file = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
  };

  const changeHandler = (event: any) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        setNewProducts(results.data);
      },
    });
  };

  const handleLoadKit = async () => {
    try {
      if (!id) return;
      const kit = await getKit({ id });
      if (!kit?.data?.pro_in_codigo) throw new Error("Kit não encontrado!");

      setkitName(kit?.data?.pro_st_descricao || "");
      setkitDescription(kit?.data?.pro_st_nomecomplem || "");
      setkitHistory(kit?.data?.pro_lt_historia || "");
      setkitAbout(kit?.data?.pro_lt_sobre || "");
      setIsActive(!!kit?.data?.pro_bo_ativo);
      setkitProducts(
        kit?.data?.kititem.map(
          (kitItem: {
            produto: {
              pro_st_sku: any;
              pro_st_descricao: any;
              pro_de_precode: any;
              pro_de_precopor: any;
              pro_de_precoatacado: any;
            };
            kti_in_amount: any;
          }) => ({
            "Sku Mln": kitItem.produto.pro_st_sku,
            Nome: kitItem.produto.pro_st_descricao,
            Quantidade: kitItem.kti_in_amount,
            "Preço De": Number(kitItem.produto.pro_de_precode),
            "Preço Por": Number(kitItem.produto.pro_de_precopor),
            "Preço Atacado": Number(kitItem.produto.pro_de_precoatacado),
          })
        ) || []
      );

      if (!kit?.data?.imagens?.[0]?.pim_st_imagesrc) return;

      const productImg = await getImageDownloadUrlFromFireBase(
        kit?.data?.imagens?.[0]?.pim_st_imagesrc
      );
      setPreview(productImg);
    } catch (error) {
      toast.error(`Erro ao carregar kit \n${error}`);
    }
  };

  const handleAddProducts = () => {
    setkitProducts([
      ...kitProducts,
      ...newProducts.map(
        (newProduct: { [x: string]: any; Quantidade: any }) => ({
          "Sku Mln": newProduct["Sku Mln"],
          Nome: newProduct["Nome do produto (opcional)"] || "",
          Quantidade: newProduct.Quantidade,
          "Preço De": 0,
          "Preço Por": 0,
        })
      ),
    ]);

    setNewProducts([]);
  };

  const handleRemoveItemFromKit = (id: string) => {
    setkitProducts(
      kitProducts.filter((kitItem: any) => kitItem["Sku Mln"] !== id)
    );
  };

  const handleSetSingleProduct = (product: any) => {
    setNewProducts([
      {
        "Sku Mln": product.sku,
        Quantidade: product.Quantidade,
        "Nome do produto (opcional)": product.nome,
      },
    ]);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  useEffect(() => {
    handleLoadKit();
  }, []);

  const handleUpdateKit = async () => {
    setIsWaitingApi(true);
    try {
      if (!selectedFile && !preview)
        throw new Error("Erro ao carregar a imagem.");
      if (!id) throw new Error("Erro ao carregar o kit.");

      const res = await updateKit({
        id,
        kitName,
        kitDescription,
        kitAbout,
        kitHistory,
        isActive,
        newProducts: kitProducts,
        image: selectedFile,
      });

      if (res.status === 201) {
        toast.success("Kit atualizado com sucesso!");
        navigate("/kits");
      }
    } catch (error) {
      console.log(error);

      toast.error(`Erro ao criar Kit: \n${error}`);
    }
    setIsWaitingApi(false);
  };

  return (
    <section className="wrapper">
      <Title>Edição de Kit</Title>

      <DivFlex>
        <DivBlock>
          <Form>
            <FormInput
              title="Nome do Kit"
              placeholder="Digite um nome aqui"
              setValue={setkitName}
              type="text"
              value={kitName}
              required
              width="300px"
              height="2rem"
              borderRadius="2px"
            />
            <FormTextArea
              title="Descrição do Kit"
              placeholder="Digite uma descrição aqui"
              setValue={setkitDescription}
              type="text"
              value={kitDescription}
              required
              width="300px"
              height="6rem"
              aria-multiline="true"
            />
            <FormTextArea
              title="Sobre o Produto"
              placeholder="Digite uma descrição aqui"
              setValue={setkitAbout}
              type="text"
              value={kitAbout}
              required
              width="300px"
              height="6rem"
              aria-multiline="true"
            />
            <FormTextArea
              title="História do Kit"
              placeholder="Digite uma descrição aqui"
              setValue={setkitHistory}
              type="text"
              value={kitHistory}
              required
              width="300px"
              height="6rem"
              aria-multiline="true"
            />
            <FormFilePicker
              required={true}
              title="Escolha a imagem do kit"
              placeholder="Digite uma URL da web aqui"
              type="file"
              accept="image/jpeg"
              inputRef={file}
              onChange={onSelectFile}
            />
            <ImgContainer>
              {preview && <img src={preview} alt={"Imagem Kit"} />}
            </ImgContainer>

            <FormCheckBox
              title="Ativo"
              setValue={setIsActive}
              value={isActive}
            />
            <Button
              variant="light"
              onClick={handleUpdateKit}
              disabled={
                !kitName || !kitDescription || !kitHistory || isWaitingApi
              }
            >
              Salvar
            </Button>
          </Form>
        </DivBlock>
        <DivBlock>
          <DivBlock>
            <SubTitle>Produtos do Kit</SubTitle>
            <OrderedTable
              rows={kitProducts?.map((item: any) => ({
                ...item,
                Remover: (
                  <Button
                    variant="light"
                    onClick={() => handleRemoveItemFromKit(item["Sku Mln"])}
                  >
                    Remover
                  </Button>
                ),
              }))}
              rowsFormatting={{
                "Preço Por": formatNumberToBRL,
                "Preço De": formatNumberToBRL,
                "Preço Atacado": formatNumberToBRL,
              }}
            ></OrderedTable>
          </DivBlock>
          <SubTitle>Adicione mais produtos ao kit</SubTitle>
          <ProductFinderHelper setProduct={handleSetSingleProduct} />
          <DivFlex>
            <DivBlock>
              <TemplateButton
                data={mockData}
                headers={mockHeaders}
                filename={"template_produtos_kits.csv"}
                variant="light"
              >
                Baixar template
              </TemplateButton>
            </DivBlock>
            <DivBlock>
              <FileInput id="file" onChange={changeHandler} />
            </DivBlock>
          </DivFlex>
          {newProducts && (
            <DivFlex>
              <DivBlock>
                <DivFlex>
                  <DivBlock>
                    <strong>Itens a serem incluídos:</strong>
                    <small>
                      Pressione o botão "Adicionar" para adicionar os produtos
                      ao Kit (ainda será necessario Salvar para que as
                      modificações sejam aplicadas).
                    </small>
                  </DivBlock>
                  <DivBlock>
                    <Button
                      variant="light"
                      onClick={handleAddProducts}
                      disabled={isWaitingApi}
                    >
                      {`${isWaitingApi ? "Carregando..." : "Adicionar"}`}
                    </Button>
                  </DivBlock>
                </DivFlex>

                <OrderedTable rows={newProducts} />
              </DivBlock>
            </DivFlex>
          )}
        </DivBlock>
      </DivFlex>
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1rem;
  font-weight: 400;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 4rem;

  strong {
    font-size: 1.5rem;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-top: 1rem;
  background-color: #fff;
  padding: 2px;
  width: 200px;
  height: 200px;

  img {
    width: 200px;
    height: 200px;
    object-fit: contain;
  }
`;

const FileInput = styled.input.attrs({
  type: "file",
  accept: ".csv",
})`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  height: 2.5rem;
  padding: 0.25rem 2rem;
  cursor: pointer;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text_light};
  font-weight: 600;
  width: max-content;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }

  &::file-selector-button {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    color: ${(props) => props.theme.colors.text_light};
    font-weight: 600;
    width: max-content;
  }
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }

  strong {
    margin-top: 2rem;
  }

  table {
    margin-top: 1rem;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
