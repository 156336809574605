import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  padding: 50px 0;
  width: 100%;
  max-width: 1200px;
`;

export const DataPickerContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 320px;
`;

export const DataSelectButton = styled.div`
  position: relative;
`;

export const ButtonDescriptionContainer = styled.div`
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #85b074;
  padding: 10px;
  color: #85b074;

  &.bgc {
    background-color: #fff;
  }

  &:hover {
    background-color: #85b074;
    color: white;
  }
`;

export const ProductsDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid grey;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;
