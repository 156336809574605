export const mockHeaders = [
  { label: "Sku Mln", key: "productSku" },
  { label: "Id do produto (opcional)", key: "productId" },
  { label: "Nome do produto (opcional)", key: "productName" },
];
export const mockData = [
  {
    productSku: "cve00001",
    productId: "10",
    productName: `nome produto ${1}`,
  },
  {
    productSku: "cve00002",
    productId: "20",
    productName: `nome produto ${2}`,
  },
  {
    productSku: "cve00003",
    productId: "30",
    productName: `nome produto ${3}`,
  },
  {
    productSku: "cve00004",
    productId: "40",
    productName: `nome produto ${4}`,
  },
  {
    productSku: "cve00005",
    productId: "50",
    productName: `nome produto ${5}`,
  },
];
