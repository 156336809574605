import axios from "axios";
import configs from "../appConfigs";

interface IGetClientsParams {
  page: number;
  ref?: string;
}

export async function getClients({ page, ref }: IGetClientsParams) {
  const { data } = await axios.get(
    `${configs.apiBaseUrl}/admin/client?page=${page}&limit=15&ref=${ref || ""}`
  );

  return data;
}
