import { OrderT, ProductT } from "../services/types";
import { formatDate } from "./data";
import getPaymentDescription from "./paymentTypes";
import { formatPrice, getDiscount } from "./price";

function isParsable(object: string) {
  try {
    JSON.parse(object);

    return true;
  } catch (error) {
    return false;
  }
}

function getProductString(products: ProductT[]) {
  let produtoString = "";

  products.forEach(
    (prod, index) =>
      (produtoString += `${index !== 0 ? "  |  " : ""}${prod.nome} - ${
        prod.quantidade
      } - R$${prod.preco}`)
  );

  // prevent CSV errors with , and .
  return produtoString.replaceAll(",", " ").replaceAll(".", " ");
}

function formatOrdersToCsv(orders: OrderT[]) {
  return orders.map((order) => {
    const {
      codPedido,
      createdAt,
      tipoPagamento,
      status,
      cliente,
      cupom,
      produtos,
    } = order;

    const endereco = isParsable(order.cliente.endereco)
      ? JSON.parse(order.cliente.endereco)
      : {};

    const { frete, produtos: produtosValue } = order.valores;
    const discounts = getDiscount(order.cupom, produtosValue, order);
    const total = Number(produtosValue) + Number(frete) - Number(discounts);

    // number was called end_in_numero, and it was changed to end_st_numero
    // this ensure both field-names are handled
    const addressNumber = endereco.end_st_numero
      ? endereco.end_st_numero
      : endereco.end_in_numero
      ? endereco.end_in_numero
      : "S/N";

    const formatted = {
      codpedido: codPedido,
      datapedido: formatDate(createdAt),
      pagamento: getPaymentDescription(tipoPagamento),
      status: status || "sem status",
      nome: cliente.nome,
      cpf: cliente.cpf,
      endereco: `${endereco.end_st_logradouro}, ${addressNumber}`,
      complemento: endereco.end_st_complemento,
      bairro: endereco.end_st_bairro,
      cep: endereco.end_st_cep,
      produtos: getProductString(produtos),
      frete: formatPrice(Number(frete)),
      cupom: cupom?.cup_st_codigo || "",
      descontos: formatPrice(Number(discounts)),
      total_produtos: formatPrice(Number(produtosValue)),
      total: formatPrice(Number(total)),
    };

    return formatted;
  });
}

function formatOrdersSplittedToCsv(orders: OrderT[]) {
  return orders.map((order) => {
    const {
      codPedido,
      createdAt,
      tipoPagamento,
      status,
      cliente,
      cupom,
      produtos,
    } = order;

    const endereco = isParsable(order.cliente.endereco)
      ? JSON.parse(order.cliente.endereco)
      : {};

    const { frete, produtos: produtosValue } = order.valores;
    const discounts = getDiscount(order.cupom, produtosValue, order);
    const total = Number(produtosValue) + Number(frete) - Number(discounts);
    const totalForSeller = formatPrice(order.amountSeller / 100);
    const sellerName = order.sellerName;
    const takeSeller = `${(Number(order.takeSeller) * 100) / 100} %`;

    // number was called end_in_numero, and it was changed to end_st_numero
    // this ensure both field-names are handled
    const addressNumber = endereco.end_st_numero
      ? endereco.end_st_numero
      : endereco.end_in_numero
      ? endereco.end_in_numero
      : "S/N";

    const formatted = {
      codpedido: codPedido,
      datapedido: formatDate(createdAt),
      pagamento: getPaymentDescription(tipoPagamento),
      status: status || "sem status",
      nome: cliente.nome,
      cpf: cliente.cpf,
      endereco: `${endereco.end_st_logradouro}, ${addressNumber}`,
      complemento: endereco.end_st_complemento,
      bairro: endereco.end_st_bairro,
      cep: endereco.end_st_cep,
      produtos: getProductString(produtos),
      frete: formatPrice(Number(frete)),
      cupom: cupom?.cup_st_codigo || "",
      descontos: formatPrice(Number(discounts)),
      total_produtos: formatPrice(Number(produtosValue)),
      total: formatPrice(Number(total)),
      sellerName: sellerName,
      totalSeller: totalForSeller,
      takeSeller: takeSeller,
    };

    return formatted;
  });
}

export { formatOrdersToCsv, formatOrdersSplittedToCsv };
