import { Link } from "react-router-dom";
import styled from "styled-components";
import { BsFillFileEarmarkBarGraphFill } from "react-icons/bs";

export default function DashboardGalleryItem({
  url,
  title,
}: {
  url: string;
  title: string;
}) {
  return (
    <Link to={url} style={{ textDecoration: "none" }}>
      <DashboardGaleryCard>
        <BsFillFileEarmarkBarGraphFill color="#FFF" />
        <span>{title}</span>
      </DashboardGaleryCard>
    </Link>
  );
}

const DashboardGaleryCard = styled.div`
  min-width: 100px;
  min-height: 100px;
  background: #85b074;
  margin-right: 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #fff;
  span {
    text-decoration: none;
    font-size: 1rem;
    text-align: center;
    max-width: 90%;
  }

  svg {
    font-size: 3rem;
  }
`;
