import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

export type TItem = {
  clp_bo_ativo: boolean;
  clp_st_ordem: string;
  clu_in_codigo: number;
  pro_in_codigo: number;
};

export async function putCollectionsProducts(item: TItem[]) {
  try {
    await axios.put(`${configs.apiBaseUrl}/admin/colecoes_produtos`, item);

    toast.success("Coleção atualizada com sucesso!");
  } catch (error) {
    toast.error(
      `Erro ao tentar atualizar coleção, por favor tente novamente mais tarde ou 
      entre em contato com um administrador \n", ${error}`
    );
  }
}
