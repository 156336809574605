import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

export async function updateOrCreateShippingPromotion({
  promotionId,
  name,
  begin,
  end,
  startDate,
  endDate,
  promotionType,
  discount,
}: {
  promotionId?: string;
  name: string;
  begin: string;
  end: string;
  startDate: string;
  endDate: string;
  promotionType: string;
  discount: string;
}) {
  try {
    return await axios.post(`${configs.apiBaseUrl}/api/promotions/shipping`, {
      promotionId,
      name,
      begin,
      end,
      startDate,
      endDate,
      promotionType,
      discount,
    });
  } catch (error) {
    toast.error(`Erro ao tentar criar promoção \n", ${error}`);
  }
}

export async function getShippingPromotion({ id }: { id: string }) {
  try {
    const promotion = await axios.get(
      `${configs.appApiBaseUrl}/promotions/shipping/${id}`
    );

    return promotion?.data?.data || null;
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar promoções de frete da API \n", ${error}`
    );
  }
}

export async function getShippingPromotions() {
  try {
    const promotion = await axios.get(
      `${configs.appApiBaseUrl}/promotions/shipping`
    );

    return promotion?.data?.data || [];
  } catch (error) {
    toast.error(
      `Erro ao tentar recuperar promoções de frete da API \n", ${error}`
    );
  }
}

export async function deleteShippingPromotion({ id }: { id: string }) {
  try {
    return await axios.delete(
      `${configs.apiBaseUrl}/api/promotions/shipping/${id}`
    );
  } catch (error) {
    toast.error(`Erro ao tentar excluir regionalização \n", ${error}`);
  }
}
