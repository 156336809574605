import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  format,
} from "date-fns";
import { useEffect, useState } from "react";
import { fetchSalesDashboard } from "../../services/salesDashboardService";
import {
  ButtonDescriptionContainer,
  CalendarContainer,
  Card,
  CardsContainer,
  CardTitle,
  Container,
  DataPickerContainer,
  DataSelectButton,
  DateFiltersContainer,
  FilterButton,
} from "./salesDashboard.style";
import {
  DateRangePicker,
  IDateRangePickerResponse,
} from "../../components/DateRangePicker";
import { FaWindowClose } from "react-icons/fa";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const SalesDashboard = () => {
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(
    startOfWeek(new Date(), {
      weekStartsOn: 0,
    }).toISOString()
  );
  const [endDate, setEndDate] = useState(
    endOfWeek(new Date(), { weekStartsOn: 0 }).toISOString()
  );
  const [sales, setSales] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<
    "Hoje" | "Semana" | "Mês" | "Ano" | "Range"
  >("Semana");
  const [ordersValueChart, setOrdersValueChart] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchSalesDashboard({ startDate, endDate });
      const ordersValueChart = response?.pedidosAppPorData.map((item: any) => ({
        data: item.date,
        totalPedidos: item.totalPedidos,
        totalValorPedidos:
          Number(item.totalValorProdutos) + Number(item.totalFrete),
      }));
      setOrdersValueChart(ordersValueChart);
      setSales(response);
    };
    fetchData();
  }, [startDate, endDate]);

  function updateDateRange(option: string) {
    switch (option) {
      case "Hoje":
        setStartDate(startOfDay(new Date()).toISOString());
        setEndDate(endOfDay(new Date()).toISOString());
        break;
      case "Semana":
        setStartDate(
          startOfWeek(new Date(), {
            weekStartsOn: 0,
          }).toISOString()
        );
        setEndDate(endOfWeek(new Date(), { weekStartsOn: 0 }).toISOString());
        break;
      case "Mês":
        setStartDate(startOfMonth(new Date()).toISOString());
        setEndDate(endOfMonth(new Date()).toISOString());
        break;
      case "Ano":
        setStartDate(startOfYear(new Date()).toISOString());
        setEndDate(endOfYear(new Date()).toISOString());
        break;
      default:
        throw new Error("Opção inválida");
    }
  }

  const handleSelectFilter = (filter: "Hoje" | "Semana" | "Mês" | "Ano") => {
    setSelectedFilter(filter);
    updateDateRange(filter);
  };

  function HandleOnChangeDate({
    selection: { endDate, startDate },
  }: IDateRangePickerResponse) {
    const startDateString = startDate.toISOString();
    const endDateString = endDate.toISOString();

    setSelectedFilter("Range");
    setStartDate(startDateString);
    setEndDate(endDateString);
  }

  function HandleDataSelectButton() {
    return (
      <DataSelectButton onClick={() => setShowCalendar((current) => !current)}>
        <ButtonDescriptionContainer>
          {selectedFilter === "Range" && startDate && endDate
            ? `${format(new Date(startDate), "dd/MM/yy")} - ${format(
                new Date(endDate),
                "dd/MM/yy"
              )}`
            : "Selecionar uma data"}
        </ButtonDescriptionContainer>
        {showCalendar && (
          <DataPickerContainer
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span
              onClick={(e) => {
                e.stopPropagation();
                setShowCalendar((current) => !current);
              }}
              aria-hidden
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#fff",
                cursor: "pointer",
              }}
            >
              <FaWindowClose color="red" />
            </span>
            <ButtonDescriptionContainer
              className="bgc"
              onClick={() => {
                setShowCalendar(false);
                setSelectedFilter("Hoje");
                updateDateRange("Hoje");
              }}
            >
              Limpar Data
            </ButtonDescriptionContainer>

            <DateRangePicker
              onChange={(props) => HandleOnChangeDate(props)}
              startDate={startDate}
              endDate={endDate}
            />
          </DataPickerContainer>
        )}{" "}
      </DataSelectButton>
    );
  }

  const currencyFormatter = (value: any) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  return (
    <Container>
      <CalendarContainer>{HandleDataSelectButton()}</CalendarContainer>
      <DateFiltersContainer>
        <FilterButton
          onClick={() => handleSelectFilter("Hoje")}
          className={selectedFilter === "Hoje" ? "active" : ""}
        >
          Hoje
        </FilterButton>
        <FilterButton
          onClick={() => handleSelectFilter("Semana")}
          className={selectedFilter === "Semana" ? "active" : ""}
        >
          Semana
        </FilterButton>
        <FilterButton
          onClick={() => handleSelectFilter("Mês")}
          className={selectedFilter === "Mês" ? "active" : ""}
        >
          Mês
        </FilterButton>
        <FilterButton
          onClick={() => handleSelectFilter("Ano")}
          className={selectedFilter === "Ano" ? "active" : ""}
        >
          Ano
        </FilterButton>
      </DateFiltersContainer>
      <CardsContainer>
        <Card>
          <CardTitle>Valor:</CardTitle>
          <b>{sales.totalPedidosAppValor || "R$ 0,00"}</b>
        </Card>
        <Card>
          <CardTitle>Quantidade:</CardTitle>
          <b>{sales.totalPedidosApp || 0}</b>
        </Card>
      </CardsContainer>
      <div style={{ marginTop: "50px" }}>
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={ordersValueChart} barSize={50}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip formatter={(value) => currencyFormatter(value)} />
            <XAxis dataKey="data" />
            <YAxis />
            <Legend />
            <Bar dataKey="totalValorPedidos" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div style={{ marginTop: "50px" }}>
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={ordersValueChart} barSize={50}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="data" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalPedidos" fill="#888" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

export default SalesDashboard;
