import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import BarChartGraph from "../../components/BarChartGraph";
import Button from "../../components/Button";
import OrderedTable from "../../components/OrderedTable";
import PieChartGraph from "../../components/PieChartGraph";
import {
  getsigaturesCombinedDataResponse,
  getSignaturesByDate,
  getSignaturesByMonth,
  getSignaturesCancelledByDate,
  getSignaturesCounted,
  getSignaturesForms,
  getSignaturesPayerStatus,
  getSignaturesProfit,
  getSignaturesStatus,
  triggerDatabaseUpdate,
} from "../../services/signatureService";
import { SalesRecord } from "../../services/types";
import theme from "../../styles/colors";
import { getSumOfSignaturesOfDay } from "../../utils/getSumOfSignaturesOfDay";
import { TemplateButton } from "../ProductRestrictions/RestrictionItem";

const colors = theme.chartColors;

export function SignatureDashboard() {
  const [signaturesByMonth, setSignaturesByMonth] = useState<
    { [key: string]: string; month: any }[]
  >([]);
  const [signaturesByDate, setSignaturesByDate] = useState<
    { [key: string]: string; date: any }[]
  >([]);
  const [signaturesCancelledByDate, setSignaturesCancelledByDate] = useState<
    { [key: string]: string; date: any }[]
  >([]);
  const [signaturesCounted, setSignaturesCounted] = useState<
    {
      value: number;
      name: string;
    }[]
  >([] as { value: number; name: string }[]);
  const [signaturesProfit, setSignaturesProfit] = useState([]);
  const [signaturesForms, setSignaturesForms] = useState([]);
  const [signaturesStatus, setSignaturesStatus] = useState([]);
  const [sigaturesCombinedData, setSigaturesCombinedData] = useState([]);
  const [signaturesPayersStatus, setSignaturesPayersStatus] = useState([]);

  const [loading, setLoading] = useState<boolean>(false);

  async function handleGetData() {
    const signaturesByMonthResponse = await getSignaturesByMonth();
    if (signaturesByMonthResponse)
      setSignaturesByMonth(signaturesByMonthResponse);

    const signaturesByDateResponse = await getSignaturesByDate();
    if (signaturesByDateResponse) setSignaturesByDate(signaturesByDateResponse);

    const signaturesCancelledByDateResponse =
      await getSignaturesCancelledByDate();
    if (signaturesCancelledByDateResponse)
      setSignaturesCancelledByDate(signaturesCancelledByDateResponse);

    const signaturesCountedResponse = await getSignaturesCounted();
    if (signaturesCountedResponse)
      setSignaturesCounted(signaturesCountedResponse);

    const signaturesProfitResponse = await getSignaturesProfit();
    if (signaturesProfitResponse) setSignaturesProfit(signaturesProfitResponse);

    const signaturesStatusResponse = await getSignaturesStatus();
    if (signaturesStatusResponse) setSignaturesStatus(signaturesStatusResponse);

    const signaturesFormsResponse = await getSignaturesForms();
    if (signaturesFormsResponse) setSignaturesForms(signaturesFormsResponse);

    const sigaturesCombinedDataResponse =
      await getsigaturesCombinedDataResponse();
    if (sigaturesCombinedDataResponse)
      setSigaturesCombinedData(sigaturesCombinedDataResponse);

    const signPayersStatus = await getSignaturesPayerStatus();
    if (signPayersStatus) setSignaturesPayersStatus(signPayersStatus);
  }

  async function handleUpdateDatabase() {
    setLoading(true);
    await triggerDatabaseUpdate();
    await handleGetData();
    toast.success("Dados atualizados!");
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    handleGetData();
    setLoading(false);
  }, []);

  return (
    <SignatureDashboardContainer className="wrapper">
      <Title>Signature dashboard</Title>
      <FlexContainer style={{ justifyContent: "flex-start" }}>
        <Button
          variant="light"
          onClick={handleUpdateDatabase}
          disabled={loading}
        >
          {loading ? "Atualizando..." : "Atualizar Dados"}
        </Button>

        <TemplateButton
          style={{ marginLeft: "8px" }}
          data={sigaturesCombinedData?.[0] ? sigaturesCombinedData : []}
          headers={
            sigaturesCombinedData?.[0]
              ? Object.keys(sigaturesCombinedData[0])
              : []
          }
          filename={"signatures_mp.csv"}
          variant="light"
        >
          {sigaturesCombinedData?.[0]
            ? "Baixar Relatório"
            : "Carregando dados..."}
        </TemplateButton>
      </FlexContainer>

      <FlexContainer>
        <BarChartGraph
          data={signaturesByMonth}
          width={600}
          height={400}
          labelKey={"month"}
          title={"Assinaturas Ativas"}
          colors={colors}
        />
        <PieChartGraph
          data={signaturesCounted}
          width={400}
          height={300}
          labelKey={"name"}
          title={"Share das Assinaturas ativas"}
          colors={colors}
        />
      </FlexContainer>

      <FlexContainer>
        <BarChartGraph
          data={signaturesByDate}
          width={1200}
          height={400}
          labelKey={"date"}
          title={"Assinaturas por dia"}
          colors={colors}
        />
      </FlexContainer>

      <FlexContainer>
        <BarChartGraph
          data={signaturesCancelledByDate}
          width={1200}
          height={400}
          labelKey={"date"}
          title={"Assinaturas canceladas por dia"}
          colors={colors}
        />
      </FlexContainer>

      <FlexContainer>
        <div>
          <SubTitle>Valor das Assinaturas</SubTitle>
          <small>
            *O valor acumulado representa o montante total cobrado dos clientes
            ao longo dos meses.
          </small>
          <OrderedTable rows={signaturesProfit} />
          <FlexContainer>
            <div>
              Quantidade:
              {signaturesProfit.reduce((accumulator, current) => {
                return accumulator + current["Total"];
              }, 0)}
            </div>
            <div>
              Total: R${` `}
              {signaturesProfit
                .reduce((accumulator, current) => {
                  return accumulator + current["Valor Total"];
                }, 0)
                .toLocaleString()}
            </div>
            <div>
              *Total Acc: R${` `}
              {signaturesProfit
                .reduce((accumulator, current) => {
                  return accumulator + current["*Valor Acc."];
                }, 0)
                .toLocaleString()}
            </div>
          </FlexContainer>
        </div>

        <div>
          <SubTitle>Envios de formaulários</SubTitle>
          <small>*Envios de teste estão inclusos nos valores.</small>
          <OrderedTable rows={signaturesForms} />
        </div>

        <div>
          <SubTitle>Status das assinaturas</SubTitle>
          <OrderedTable rows={signaturesStatus} />
        </div>
      </FlexContainer>

      <Title>Provisionamento Signature para o mês atual</Title>
      <FlexContainer>
        <OrderedTable
          rows={calculateMonthProvisions({
            signaturesCounted,
            signaturesByDate,
            signaturesCancelledByDate,
          })}
        />
      </FlexContainer>

      <Title>Signatures Status</Title>
      <small>
        *Essa lista está apresentando cada assinante e a situação da sua
        assinatura.
      </small>
      <FlexContainer>
        <OrderedTable rows={signaturesPayersStatus} />
      </FlexContainer>
    </SignatureDashboardContainer>
  );
}

interface MonthEntryCount {
  [key: string]: number;
}

function countEntriesByMonth(records: SalesRecord[]): MonthEntryCount {
  const entryCountByMonth: MonthEntryCount = {};

  for (const record of records) {
    const recordDateParts: string[] = record.date.split("/");
    const recordMonth: number = parseInt(recordDateParts[1]);
    const monthKey: string = `${recordMonth + 1}`;

    if (!(monthKey in entryCountByMonth)) {
      entryCountByMonth[monthKey] = 0;
    }

    entryCountByMonth[monthKey] += getSumOfSignaturesOfDay(record);
  }

  return entryCountByMonth;
}

function calculateRewardEntries(records: SalesRecord[]) {
  const now: Date = new Date();
  const currentMonth: number = now.getMonth() + 1;
  const totalSignaturesByMonth = countEntriesByMonth(records);

  return (
    (totalSignaturesByMonth?.[
      1 + currentMonth > 12 ? 1 + currentMonth - 12 : 1 + currentMonth
    ] || 0) +
    (totalSignaturesByMonth?.[
      4 + currentMonth > 12 ? 4 + currentMonth - 12 : 4 + currentMonth
    ] || 0) +
    (totalSignaturesByMonth?.[
      7 + currentMonth > 12 ? 7 + currentMonth - 12 : 7 + currentMonth
    ] || 0) +
    (totalSignaturesByMonth?.[
      10 + currentMonth > 12 ? 10 + currentMonth - 12 : 10 + currentMonth
    ] || 0)
  );
}

function sumSalesByMonth(records: SalesRecord[]): {
  prevMonthSum: number;
  currentMonthSum: number;
} {
  const now: Date = new Date();
  const currentYear: number = now.getFullYear() - 2000;
  const currentMonth: number = now.getMonth();

  let prevMonthSum: number = 0;
  let currentMonthSum: number = 0;

  for (const record of records) {
    const recordDateParts: string[] = record.date.split("/");
    const recordYear: number = parseInt(recordDateParts[2]);
    const recordMonth: number = parseInt(recordDateParts[1]) - 1; // Months are 0-indexed in JavaScript Date

    if (
      recordYear < currentYear ||
      (recordYear === currentYear && recordMonth < currentMonth)
    ) {
      // Sum values for previous months
      for (const key in record) {
        if (key !== "date") {
          prevMonthSum += Number(
            typeof record[key] === "number" ? record[key] : 0
          );
        }
      }
    } else if (recordYear === currentYear && recordMonth === currentMonth) {
      // Sum values for the current month
      for (const key in record) {
        if (key !== "date") {
          currentMonthSum += Number(
            typeof record[key] === "number" ? record[key] : 0
          );
        }
      }
    }
  }

  return { prevMonthSum, currentMonthSum };
}

function calculateMonthProvisions({
  signaturesCounted,
  signaturesByDate,
  signaturesCancelledByDate,
}: {
  signaturesCounted: {
    value: number;
    name: string;
  }[];
  signaturesByDate: SalesRecord[];
  signaturesCancelledByDate: SalesRecord[];
}) {
  const {
    prevMonthSum: AllActiveSignaturesUntilNow,
    currentMonthSum: newThisMonth,
  } = sumSalesByMonth(signaturesByDate);
  const { currentMonthSum: cancelledThisMonth } = sumSalesByMonth(
    signaturesCancelledByDate
  );
  const daysThisMonth = daysSinceStartOfMonth();
  const totalSignatures = signaturesCounted.reduce((acc, cur) => {
    return acc + cur.value;
  }, 0);

  const gold2Share =
    signaturesCounted?.filter(
      (row: { value: number; name: string }) =>
        row.name === "Gold 2 - Frete Grátis"
    )?.[0]?.value / totalSignatures;
  const gold4Share =
    signaturesCounted?.filter((row) => row.name === "Gold 4 - Frete Grátis")[0]
      ?.value / totalSignatures;

  const plat2Share =
    (signaturesCounted?.filter((row) => row.name === "Platinum 2")?.[0]?.value +
      signaturesCounted.filter(
        (row) => row.name === "Platinum 2 - Frete Grátis"
      )?.[0]?.value) /
    totalSignatures;
  const plat4Share =
    signaturesCounted?.filter(
      (row) => row.name === "Platinum 4 - Frete Grátis"
    )?.[0]?.value / totalSignatures;

  const totalMonthProvisions =
    (newThisMonth / daysThisMonth) * 30 -
    (cancelledThisMonth / daysThisMonth) * 30;
  const totalProvisions =
    (AllActiveSignaturesUntilNow + totalMonthProvisions) * 1.2;

  return [
    {
      "Gold 2": Math.ceil(gold2Share * totalProvisions),
      "Gold 4": Math.ceil(gold4Share * totalProvisions),
      "Platinum 2": Math.ceil(plat2Share * totalProvisions),
      "Platinum 4": Math.ceil(plat4Share * totalProvisions),
      Brindes: calculateRewardEntries(signaturesByDate),
    },
  ];
}

function daysSinceStartOfMonth(): number {
  const now: Date = new Date();
  const startOfMonth: Date = new Date(now.getFullYear(), now.getMonth(), 1);
  const timeDifference: number = now.getTime() - startOfMonth.getTime();
  const daysSinceStart: number =
    Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
  return daysSinceStart;
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
  padding-bottom: 2rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SignatureDashboardContainer = styled.section`
  margin-bottom: 5rem;
  table {
    margin: 0.5rem 0;
  }
`;
