import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header";
import ClientData from "./ClientData";
import ProductList from "./ProductList";
import PaymentSummary from "./PaymentSummary";
import { OrderT, SplitT } from "../../services/types";
import { getPedidoSplittedById } from "../../services/ordersService";
import SplitList from "./SplitList";

export default function OrderSplittedDetails() {
  const { id } = useParams();
  const [order, setOrder] = useState<OrderT>();

  async function fetchOrders() {
    const _order = await getPedidoSplittedById(id);

    setOrder(_order);
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <section className="wrapper">
      {order ? (
        <div>
          <Header order={order} />

          <ClientData client={order.cliente} />

          <ProductList products={order.produtos} />

          <PaymentSummary
            cupom={order.cupom}
            values={order.valores}
            order={order}
          />

          <SplitList order={order} />
        </div>
      ) : (
        <div>carregando...</div>
      )}
    </section>
  );
}
