import { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import {
  getCollectionsAndCount,
  putCollection,
} from "../../services/collectionService";
import styled from "styled-components";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { ClusterAndCountT } from "../../services/types";

export default function ClusterList() {
  const [cluster, setCluster] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const navigator = useNavigate();

  const getClusters = async () => {
    setLoading(true);
    const clusters = await getCollectionsAndCount();
    setCluster(clusters);
    setLoading(false);
    return clusters;
  };

  useEffect(() => {
    getClusters();
  }, []);

  if (loading) {
    return (
      <section className="wrapper">
        <div>carregando...</div>
      </section>
    );
  }

  function EditButton({ currentCluster }: { currentCluster: any }) {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [url, setUrl] = useState<string>(currentCluster.clu_st_url || "");

    const handleOnChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value);
    };

    const handleOnCancel = () => {
      setIsDisabled((oldValue) => !oldValue);
      setUrl(currentCluster.clu_st_url);
    };

    const handleOnSave = async () => {
      await putCollection({
        clu_in_codigo: currentCluster.clu_in_codigo,
        clu_st_url: url,
      }).then(() => {
        const newClusterState = cluster
          .map((c: any) => {
            if (c.clu_in_codigo === currentCluster.clu_in_codigo) {
              return { ...c, clu_st_url: url };
            }
            return c;
          })


        setIsDisabled((oldValue) => !oldValue);
        setCluster(newClusterState);
      })
    };

    return (
      <>
        <Cell>
          <input
            disabled={isDisabled}
            style={{ padding: "5px 0" }}
            value={url}
            onChange={(e) => handleOnChangeInput(e)}
          />
        </Cell>
        <Cell>
          {isDisabled ? (
            <Button
              onClick={() => setIsDisabled((oldValue) => !oldValue)}
              variant={"light"}
            >
              <FaRegEdit size={15} />
            </Button>
          ) : (
            <div>
              <Button onClick={() => handleOnSave()} variant={"light"}>
                Salvar
              </Button>
              <Button onClick={() => handleOnCancel()} variant={"light"}>
                Cancelar
              </Button>
            </div>
          )}
        </Cell>
      </>
    );
  }

  return (
    <section className="wrapper">
      {cluster?.length > 0 ? (
        <>
          <Row>
            <Button
              style={{ marginRight: 10 }}
              variant={"dark"}
              onClick={() => navigator("/clusters")}
            >
              Criar Coleção
            </Button>
          </Row>
          <Table>
            <HeaderCell>Nome do Cupom</HeaderCell>
            <HeaderCell>Quantidade de itens</HeaderCell>
            <HeaderCell>URL</HeaderCell>
            <HeaderCell>Editar</HeaderCell>
            <HeaderCell>-</HeaderCell>
            {cluster?.map((cluster: ClusterAndCountT) => {
              return (
                <Row>
                  <Cell>{cluster.clu_st_nome}</Cell>
                  <Cell>{parseInt(cluster.total_itens)}</Cell>
                  <EditButton currentCluster={cluster} />
                  <Cell>
                    {" "}
                    <Button
                      onClick={() =>
                        navigator(`/clustersDetails/${cluster.clu_in_codigo}`, {
                          state: cluster,
                        })
                      }
                      variant={"light"}
                    >
                      Visualizar
                    </Button>{" "}
                  </Cell>
                </Row>
              );
            })}
          </Table>
        </>
      ) : (
        <div> nenhum produto encontrado</div>
      )}
    </section>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;
