import styled from "styled-components";

export const Title = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 1000px;
  margin-bottom: 0.5rem;
`;

export const BoldText = styled.p`
  font-weight: bold;
  display: inline;
  margin-right: 10px;
`;

export const Separator = styled.hr`
  margin-top: 2rem;
  margin-bottom: 1rem;
`;
