import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

export async function createCoupon({
  cup_st_codigo,
  cup_de_porcentagem,
  cup_de_valor,
  cup_bo_fretegratis,
  cup_de_minvalue,
  cup_in_usomaximo,
  cup_st_descricao,
  cup_dt_startDate,
  cup_dt_endDate,
  blacklistedClusters,
}: any) {
  try {
    await axios.post(`${configs.apiBaseUrl}/api/coupons`, {
      cup_st_codigo,
      cup_de_porcentagem,
      cup_de_valor,
      cup_bo_fretegratis,
      cup_de_minvalue,
      cup_in_usomaximo,
      cup_st_descricao,
      cup_dt_startDate,
      cup_dt_endDate,
      blacklistedClusters,
    });
    toast.success("Cupom criado com sucesso!");
  } catch (error: any) {
    toast.error(`Erro ao tentar criar cupom \n", ${error}`);
    throw new Error(error);
  }
}

export async function getAllCoupons() {
  try {
    const coupons = await axios.get(`${configs.apiBaseUrl}/api/coupons`);

    return coupons?.data?.data;
  } catch (error: any) {
    toast.error(`Erro ao tentar trazer listagem de cupons \n", ${error}`);
    throw new Error(error);
  }
}

export async function updateCoupon(data: any) {
  try {
    await axios.put(`${configs.apiBaseUrl}/api/coupons`, data);
    toast.success("Cupom atulizado com sucesso!");
    return "Atualizado com sucesso";
  } catch (error: any) {
    toast.error(`Erro ao tentar atualizar cupom \n", ${error}`);
    throw new Error(error);
  }
}
