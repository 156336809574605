import React, { useEffect, useState } from "react";
import {
  Title,
  DivFlex,
  DivBlock,
  CardItem,
  List,
  Img,
  Text,
  DivButtons,
  Section,
  SectionInvoicing,
} from "./style";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import {
  inactivePlan,
  getSignaturesPlans,
  getAvailableInvoicing,
  sendSignaturesToMln,
} from "../../services/signatureService";
import { ResponseSignaturesPlans } from "./types";
import theme from "../../styles/colors";
import { formatNumberToBRL } from "../../utils/formatNumberToBRL";

const SignatureApp: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [available, setAvailable] = useState<boolean>(true);
  const [signatures, setSignatures] = useState<[] | ResponseSignaturesPlans[]>(
    []
  );

  async function handleGetSignaturesPlans() {
    setIsWaitingApi(true);
    const response = await getSignaturesPlans();
    const invoicingAvailable = await getAvailableInvoicing();
    setAvailable(invoicingAvailable);
    if (response) {
      setSignatures(response);
    }
    setIsWaitingApi(false);
  }

  async function setDesactivePlan(item: ResponseSignaturesPlans) {
    item.status = "cancelled";
    setIsWaitingApi(true);
    await inactivePlan(item);
    setIsWaitingApi(false);
  }

  const sendToMln = async () => {
    setIsWaitingApi(true);
    await sendSignaturesToMln();
    setIsWaitingApi(false);
  };

  const editItem = (planId: string) => {
    navigate(`/signature/selectProducts/${planId}`);
  };

  useEffect(() => {
    const getSignatures = async () => await handleGetSignaturesPlans();
    getSignatures();
  }, []);

  return (
    <>
      <Section>
        <Title>Assinaturas App</Title>
        <DivFlex>
          <DivBlock>
            <FormInput
              title=""
              setValue={setSearch}
              value={search}
              placeholder="Busca"
              width="500px"
              height="2.5rem"
              borderRadius="8px"
            />
          </DivBlock>
          <DivBlock>
            <Button
              variant="light"
              onClick={() => navigate(`/signature/create`)}
              disabled={isWaitingApi}
            >
              {isWaitingApi ? "Carregando..." : "Criar"}
            </Button>
          </DivBlock>
        </DivFlex>
        <List>
          {signatures.map((item: ResponseSignaturesPlans) => (
            <CardItem key={item.id}>
              <Img src={item.sig_st_image} alt="Signature" />
              <Text>{`Titulo: ${item.reason}`}</Text>
              <Text>{`Descricao: ${item.description_plan}`}</Text>
              <Text>{`Valor: ${formatNumberToBRL(
                item.transaction_amount
              )}`}</Text>
              <Text>{`Status: ${item.status.toUpperCase()}`}</Text>
              <DivButtons>
                {item.status === "active" && (
                  <>
                    <Button
                      variant={"light"}
                      title="Desativar"
                      customBackground={`${theme.colors.wine_red}`}
                      onClick={() => setDesactivePlan(item)}
                    >
                      Desativar
                    </Button>
                    <Button
                      variant={"light"}
                      title="Editar"
                      customBackground={`${theme.colors.primary}`}
                      onClick={() => editItem(item.sig_in_codigo)}
                    >
                      Editar
                    </Button>
                  </>
                )}
              </DivButtons>
            </CardItem>
          ))}
        </List>
      </Section>
      <SectionInvoicing>
        <hr></hr>
        <p style={{ marginTop: "10px", fontStyle: "italic", fontSize: "14px" }}>
          Este botão ficará ativo <strong>se todas as assinaturas</strong>{" "}
          tiverem produtos cadastrados para o mês anterior.
        </p>
        <Title style={{ marginTop: "10px" }}>Faturamento Signature</Title>
        <Button
          variant={"light"}
          title={isWaitingApi ? "Carregando" : "Faturar"}
          customBackground={`${theme.colors.primary}`}
          disabled={isWaitingApi || !available}
          onClick={sendToMln}
        >
          {isWaitingApi ? "Carregando" : "Faturar"}
        </Button>
      </SectionInvoicing>
    </>
  );
};

export default SignatureApp;
