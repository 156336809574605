import React, { useRef, useState } from "react";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import theme from "../../styles/colors";
import { useNavigate } from "react-router-dom";
import { Title, DivFlex, DivBlock, DivFlexForm } from "./style";
import FormFilePicker from "../../components/BannerManager/FormFilePicker";
import Dropdown from "../../components/BannerManager/Dropdown";
import { CreatePlan } from "./types";
import { createPlan } from "../../services/signatureService";

const FormCreatePlan: React.FC = () => {
  const [title, setTitle] = useState<string>("");
  const [qtyBottles, setQtyBottles] = useState<number>(0);
  const [descriptPlan, setdescriptPlan] = useState<string>("");
  const [frequency, setFrequency] = useState<number>(0);
  const [frequencyType, setfrequencyType] = useState<string>("");
  const [qtdMonths, setqtdMonths] = useState<number>(0);
  const [payDay, setpayDay] = useState<number>(0);
  const [planValue, setplanValue] = useState<number>(0);
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  const file = useRef<HTMLInputElement>();
  const navigate = useNavigate();

  function createPlanBodyRequest(): CreatePlan {
    return {
      sig_st_image: file.current?.files?.[0],
      description_plan: descriptPlan,
      sig_in_qty_bottles: qtyBottles,
      reason: title,
      auto_recurring: {
        frequency: frequency,
        frequency_type: frequencyType,
        repetitions: qtdMonths,
        billing_day: payDay,
        transaction_amount: planValue,
        currency_id: "BRL",
      },
      back_url: "https://www.sonoma.com.br/",
    };
  }

  async function handleCreatePlan() {
    setIsWaitingApi(true);
    const bodyReq = createPlanBodyRequest();
    const newPlanId = await createPlan(bodyReq);
    setIsWaitingApi(false);
    if (newPlanId) {
      navigate(`/signature/selectProducts/${newPlanId}`);
    }
  }

  return (
    <section className="wrapper">
      <Title>Criar Plano de Assinatura no App</Title>
      <DivFlexForm>
        <DivBlock>
          <FormInput
            title="Titulo Plano de Assinatura:"
            setValue={setTitle}
            value={title}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Limite 100 caracteres"
            required
          />
        </DivBlock>
        <DivBlock>
          <FormFilePicker
            required={true}
            title="Escolha a imagem do plano de assinatura:"
            placeholder="Digite uma URL da web aqui"
            type="file"
            accept="image/jpeg"
            inputRef={file}
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Quantidade de garrafas:"
            setValue={setQtyBottles}
            value={qtyBottles}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Digite a quantidade de garrafas"
            type="number"
            required
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Digite a descricao do plano:"
            setValue={setdescriptPlan}
            value={descriptPlan}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Limite 100 caracteres"
            required
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Frequencia"
            setValue={setFrequency}
            value={frequency}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Frequencia"
            type="number"
            required
          />
        </DivBlock>
        <DivBlock>
          <Dropdown
            title="Seleciona forma de cobranca"
            options={[
              { title: "Dias", value: "days" },
              { title: "Meses", value: "months" },
            ]}
            setSelected={setfrequencyType}
            selected={frequencyType}
            required
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Quantidade de meses:"
            setValue={setqtdMonths}
            value={qtdMonths}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Quantidade de meses para cobranca"
            required
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Dia de vencimento:"
            setValue={setpayDay}
            value={payDay}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Dia de vencimento"
            required
          />
        </DivBlock>
        <DivBlock>
          <FormInput
            title="Valor do plano de assinatura:"
            setValue={setplanValue}
            value={planValue}
            width="23.7rem"
            height="2.5rem"
            borderRadius="8px"
            placeholder="Valor do plano de assinatura"
            required
          />
        </DivBlock>
      </DivFlexForm>
      <DivFlex>
        <DivBlock>
          <Button
            variant="dark"
            onClick={() => navigate(`/signature`)}
            disabled={isWaitingApi}
            customBackground={theme.colors.text_light}
            border="1px solid #85b074"
          >
            {isWaitingApi ? "Carregando..." : "Voltar"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button variant="light" onClick={handleCreatePlan}>
            {isWaitingApi
              ? "Carregando..."
              : "Criar e ir para seleção de produtos"}
          </Button>
        </DivBlock>
      </DivFlex>
    </section>
  );
};

export default FormCreatePlan;
