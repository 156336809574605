import {
  AddressContentContainer,
  ContentContainer,
  ContentInformationContainer,
  InputContainer,
  SectionInformationTitle,
  StatusButton,
  StatusContainer,
  StatusDescription,
  UserEditFormContainer,
} from "./clientList.style";
import {
  DSTable,
  DSModal,
  DSInput,
  DSButton,
} from "../../utils/DesignSystem.d";
import { useEffect, useState } from "react";
import { getClients } from "../../services/getClients";
import {
  IClient,
  IDSProductsTableProps,
  IMetatag,
  TClientWithId,
} from "./interfaces";
import { putUserStatus } from "../../services/putUserStatus";
import { putClient } from "../../services/putClient";

const ClientList = () => {
  const [clients, setClients] = useState<TClientWithId[]>([]);
  const [metatag, setMetatag] = useState<IMetatag>({} as IMetatag);
  const [refValue, setRefValue] = useState<string>("");

  useEffect(() => {
    getClients({ page: 1 }).then((result) => {
      const clientWithId = result.data.map(
        (client: IClient, index: number) => ({ ...client, id: index })
      );

      setClients(clientWithId);
      setMetatag(result._metadata);
    });
  }, []);

  function HandleChangePage(page: number) {
    getClients({ page, ref: refValue }).then((result) => {
      const clientWithId = result.data.map(
        (client: IClient, index: number) => ({ ...client, id: index })
      );
      setClients(clientWithId);
      setMetatag(result._metadata);
    });
  }

  function HandleOnSearching(ref: string) {
    setRefValue(ref);
    getClients({ page: 1, ref }).then((result) => {
      const clientWithId = result.data.map(
        (client: IClient, index: number) => ({ ...client, id: index })
      );
      setClients(clientWithId);
      setMetatag(result._metadata);
    });
  }

  function HandleStatusColunm(props: IDSProductsTableProps) {
    const userActive = props?.item?.usuario?.usu_bo_active;

    return (
      <StatusContainer>
        <StatusDescription className={userActive ? "active" : "desactive"}>
          {userActive ? "Ativo" : "Inativo"}
        </StatusDescription>
      </StatusContainer>
    );
  }

  function HandleOrdersColunm(props: IDSProductsTableProps) {
    const orders = props.item.PedidoApp;

    return (
      <td>
        <p style={{ textAlign: "center" }}>{orders.length}</p>
      </td>
    );
  }

  function HandleUserEdit({ item }: IDSProductsTableProps) {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [email, setEmail] = useState<string>(item.cli_st_email);
    const [cpf, setCpf] = useState<string>(item.cli_st_cpf || "-");
    const [cnpj, setCnpj] = useState<string>(item.cli_st_cnpj || "-");

    const handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement>,
      setState: React.Dispatch<React.SetStateAction<string>>
    ) => {
      setState(event.target.value);
    };

    if (!item?.cli_in_codigo) return null;

    function handlePutClient() {
      putClient({
        id: item.cli_in_codigo,
        cli_st_email: email,
        cli_st_cpf: cpf,
        cli_st_cnpj: cnpj,
        cli_ch_sexo: item.cli_ch_sexo,
        cli_dt_datanascimento: item.cli_dt_datanascimento,
        cli_st_celular: item.cli_st_celular,
        cli_st_nome: item.cli_st_nome,
        shouldUpdateMln: true,
      }).then(() => {
        getClients({ page: 1, ref: refValue }).then((result) => {
          const clientWithId = result.data.map(
            (client: IClient, index: number) => ({ ...client, id: index })
          );
          setClients(clientWithId);
          setMetatag(result._metadata);
        });

        setOpenModal(false);
      });
    }

    return (
      <td>
        <DSModal
          modalId={item.cli_in_codigo?.toString()}
          setOpen={() => setOpenModal((prev) => !prev)}
          open={openModal}
          showButton={false}
        >
          <UserEditFormContainer>
            <InputContainer>
              <label>E-mail</label>
              <DSInput
                handleChange={(e) => handleInputChange(e, setEmail)}
                value={email}
                fullWidth
              />
            </InputContainer>
            <InputContainer>
              <label>CPF</label>
              <DSInput
                handleChange={(e) => handleInputChange(e, setCpf)}
                value={cpf}
                fullWidth
              />
            </InputContainer>
            <InputContainer>
              <label>CNPJ</label>
              <DSInput
                handleChange={(e) => handleInputChange(e, setCnpj)}
                value={cnpj}
                fullWidth
              />
            </InputContainer>
            <DSButton size="medium" onClick={() => handlePutClient()}>
              Atualizar
            </DSButton>
          </UserEditFormContainer>
        </DSModal>
        <p
          style={{
            textAlign: "center",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => setOpenModal((prev) => !prev)}
        >
          Editar
        </p>
      </td>
    );
  }

  function HandleUserStatus(props: IDSProductsTableProps) {
    const userActive = props?.item?.usuario?.usu_bo_active;

    const handleRequest = () => {
      putUserStatus({
        userId: props?.item?.usuario?.usu_in_codigo,
        status: !userActive,
      }).then(() => {
        getClients({ page: 1, ref: refValue }).then((result) => {
          const clientWithId = result.data.map(
            (client: IClient, index: number) => ({ ...client, id: index })
          );
          setClients(clientWithId);
          setMetatag(result._metadata);
        });
      });
    };
    return (
      <td>
        <p style={{ textAlign: "center" }}>
          <StatusButton active={userActive} onClick={() => handleRequest()}>
            {userActive ? "Desativar" : "Ativar"}
          </StatusButton>
        </p>
      </td>
    );
  }

  function ActionColumn({ item }: IDSProductsTableProps) {
    const [openModal, setOpenModal] = useState<boolean>(false);

    if (!item?.cli_in_codigo) return null;

    return (
      <>
        <div>
          <p
            style={{
              textAlign: "center",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setOpenModal((prev) => !prev)}
          >
            Mais Informações
          </p>
        </div>
        <DSModal
          modalId={item.cli_in_codigo?.toString()}
          setOpen={() => setOpenModal((prev) => !prev)}
          open={openModal}
          showButton={false}
        >
          <div>
            <ContentInformationContainer>
              <SectionInformationTitle>
                Informações do cliente
              </SectionInformationTitle>
              <p>
                <b>Nome:</b>&nbsp;{item.cli_st_nome || "-"}
              </p>
              <p>
                <b>Email:</b>&nbsp;{item.cli_st_email || "-"}
              </p>
              <p>
                <b>CPF:</b>&nbsp;{item.cli_st_cpf || "-"}
              </p>
              <p>
                <b>CNPJ:</b>&nbsp;{item.cli_st_cnpj || "-"}
              </p>
              <p>
                <b>Celular:</b>&nbsp;{item.cli_st_celular || "-"}
              </p>
              <p>
                <b>Criado em:</b>&nbsp;{(item.createdAt as string) || "-"}
              </p>
              <p>
                <b>Codigo MLN:</b>&nbsp;{item.cli_in_codmillenium || "-"}
              </p>
            </ContentInformationContainer>
            <ContentInformationContainer>
              <SectionInformationTitle>
                Enderecos do cliente
              </SectionInformationTitle>
              {item.enderecos.length === 0 ? (
                <p>Nenhum endereço cadastrado</p>
              ) : (
                item.enderecos.map((address) => (
                  <AddressContentContainer>
                    <p>
                      <b>Logradouro:</b>&nbsp;
                      {address.end_st_logradouro || "-"}
                    </p>
                    <p>
                      <b>Bairro:</b>&nbsp;{address.end_st_bairro || "-"}
                    </p>
                    <p>
                      <b>Cidade:</b>&nbsp;{address.end_st_cidade || "-"}
                    </p>
                    <p>
                      <b>CEP:</b>&nbsp;{address.end_st_cep || "-"}
                    </p>
                    <p>
                      <b>Complemento:</b>&nbsp;
                      {address.end_st_complemento || "-"}
                    </p>
                    <p>
                      <b>Numero:</b>&nbsp;{address.end_st_numero || "-"}
                    </p>
                    <p>
                      <b>Contato:</b>&nbsp;{address.end_st_contato || "-"}
                    </p>
                    <p>
                      <b>Telefone:</b>&nbsp;{address.end_st_fone || "-"}
                    </p>
                    <p>
                      <b>Pais:</b>&nbsp;{address.end_st_pais || "-"}
                    </p>
                    <p>
                      <b>Tipo de entrega:</b>&nbsp;
                      {address.end_st_tipoEntrega || "-"}
                    </p>
                    <p>
                      <b>Tipo de cobranca:</b>&nbsp;
                      {address.end_st_tipoCobranca || "-"}
                    </p>
                    <p>
                      <b>Tipo de outros:</b>&nbsp;
                      {address.end_st_tipoOutros || "-"}
                    </p>
                    <p>
                      <b>Tipo de principal:</b>&nbsp;
                      {address.end_st_tipoPrincipal || "-"}
                    </p>
                  </AddressContentContainer>
                ))
              )}
            </ContentInformationContainer>
            <ContentInformationContainer>
              <SectionInformationTitle>
                Pedidos do cliente
              </SectionInformationTitle>
              {item.PedidoApp.length === 0 ? (
                <p>Nenhum pedido cadastrado</p>
              ) : (
                item.PedidoApp.map((order) => (
                  <AddressContentContainer>
                    <p>
                      <b>Codigo pedido:</b>&nbsp;{order.pea_in_codigo || "-"}
                    </p>
                    <p>
                      <b>Codigo pedido MLN:</b>&nbsp;
                      {order.pea_st_codPedidoMillenium || "-"}
                    </p>
                    <p>
                      <b>Status mercado pago:</b>&nbsp;
                      {order.pea_en_statusMercadoPago || "-"}
                    </p>
                    <p>
                      <b>Status MLN:</b>&nbsp;
                      {order.pea_en_statusMillenium || "-"}
                    </p>
                    <p>
                      <b>Data vencimento:</b>&nbsp;
                      {order.pea_dt_dataVencimento}
                    </p>
                    <p>
                      <b>Data entrega:</b>&nbsp;
                      {order.pea_dt_dataEntrega || "-"}
                    </p>
                    <p>
                      <b>Pedido feito em:</b>&nbsp;{order.createdAt || "-"}
                    </p>
                  </AddressContentContainer>
                ))
              )}
            </ContentInformationContainer>
          </div>
        </DSModal>
      </>
    );
  }

  return (
    <ContentContainer>
      <div>
        <DSTable
          rows={clients}
          columns={[
            { id: "cli_st_nome", title: "Nome" },
            { id: "cli_st_email", title: "email" },
            { id: "createdAt", title: "Criado em:" },
            {
              id: "isUserActivated",
              title: "Usuario Ativo",
              component: (props) => <HandleStatusColunm {...props} />,
            },
            {
              id: "pedidos",
              title: "Pedidos",
              component: (props) => <HandleOrdersColunm {...props} />,
            },
            {
              id: "action",
              title: "",
              component: (props) => <ActionColumn {...props} />,
            },
            {
              id: "userStatus",
              title: "Ativar/Desativar",
              component: (props) => <HandleUserStatus {...props} />,
            },
            {
              id: "userEdit",
              title: "Editar",
              component: (props) => <HandleUserEdit {...props} />,
            },
          ]}
          paging={{
            limit: metatag?.paging?.limit || 0,
            page: metatag?.paging?.page || 0,
            total: metatag?.paging?.total || 0,
          }}
          onChangePage={(page) => HandleChangePage(page)}
          onSearch={(ref) => HandleOnSearching(ref)}
          refValue={refValue}
          searchPlaceholder={"Pesquisar por nome ou e-mail"}
        />
      </div>
    </ContentContainer>
  );
};

export default ClientList;
