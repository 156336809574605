import axios from "axios";
import { toast } from "react-toastify";
import configs from "../appConfigs";

interface IPedidosAppPorData {
  ProdutoPedidoApp: any[];
  date: string;
  totalFrete: string;
  totalPedidos: number;
  totalValorProdutos: string;
}

interface IFetchResponse {
  pedidosAppPorData: IPedidosAppPorData[];
  totalPedidosApp: string;
  totalPedidosAppValor: string;
}
export async function fetchSalesDashboard({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): Promise<IFetchResponse | undefined> {
  try {
    const params = new URLSearchParams({
      ...(startDate ? { startDate: String(startDate) } : {}),
      ...(endDate ? { endDate: String(endDate) } : {}),
    });

    const result: any = await axios.get(
      `${configs.apiBaseUrl}/api/sales/report?${params.toString()}`
    );

    return {
      pedidosAppPorData: result?.data?.pedidosAppPorData,
      totalPedidosApp: result?.data?.totalPedidosApp,
      totalPedidosAppValor: result?.data?.totalPedidosAppValor,
    };
  } catch (error) {
    toast.error(`Erro ao tentar carregar o dashboard \n", ${error}`);
  }
}
