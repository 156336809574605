import { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getPedidosSplitted } from "../../services/ordersService";
import { OrderT } from "../../services/types";
import { formatDate } from "../../utils/data";
import { formatOrdersSplittedToCsv } from "../../utils/orders";
import { formatPrice, getDiscount } from "../../utils/price";

const csvHeader = [
  { label: "Codigo do pedido", key: "codpedido" },
  { label: "Data pedido", key: "datapedido" },
  { label: "Método pag.", key: "pagamento" },
  { label: "Status do pedido", key: "status" },
  { label: "Nome Cliente", key: "nome" },
  { label: "CPF Cliente", key: "cpf" },
  { label: "Endereço", key: "endereco" },
  { label: "Complemento", key: "complemento" },
  { label: "Bairro", key: "bairro" },
  { label: "CEP", key: "cep" },
  { label: "Produtos", key: "produtos" },
  { label: "Valor frete", key: "frete" },
  { label: "Cupom", key: "cupom" },
  { label: "Descontos", key: "descontos" },
  { label: "Valor produtos", key: "total_produtos" },
  { label: "Valor pedido", key: "total" },
  { label: "Seller", key: "sellerName" },
  { label: "Total para Seller", key: "totalSeller" },
  { label: "Take Seller", key: "takeSeller" },
];

export default function OrderListSplitted() {
  const [orders, setOrders] = useState<OrderT[]>([]);
  const [loading, setLoading] = useState(false);

  const getOrders = async (
    ref: string,
    minDate: string,
    maxDate: string,
    limit = 50,
    saveOnContext = true
  ) => {
    setLoading(true);

    const _orders = await getPedidosSplitted(limit, ref, minDate, maxDate);

    if (saveOnContext) setOrders(_orders);

    setLoading(false);

    return Promise.resolve(_orders);
  };

  const navigator = useNavigate();

  const [ref, setRef] = useState("");
  const [minDate, setminDate] = useState("");
  const [maxDate, setmaxDate] = useState("");

  const [csvOrders, setCsvOrders] = useState([]);

  async function fetchOrders() {
    await getOrders(ref, minDate, maxDate, 50, true);

    // get all orders to CSV
    const _orders = await getOrders(ref, minDate, maxDate, 999999, false);

    setCsvOrders(_orders);
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <section className="wrapper">
      <FiltersContainer>
        <SearchInput value={ref} onChange={(e) => setRef(e.target.value)} />

        <LeftFilters>
          <DateContainer>
            <p>Data mínima</p>

            <DatePicker
              type="date"
              value={minDate}
              onChange={(e) => setminDate(e.target.value)}
            />
          </DateContainer>

          <DateContainer>
            <p>Data máxima</p>

            <DatePicker
              type="date"
              value={maxDate}
              onChange={(e) => setmaxDate(e.target.value)}
            />
          </DateContainer>

          <ApplyButton onClick={() => fetchOrders()}>Pesquisar</ApplyButton>
        </LeftFilters>
      </FiltersContainer>

      {loading ? (
        <div>carregando...</div>
      ) : orders?.length > 0 ? (
        <div style={{ justifyContent: "center" }}>
          {!loading && csvOrders.length > 0 && (
            <div style={{ marginBottom: 16 }}>
              <DownloadOrdersButton
                data={formatOrdersSplittedToCsv(csvOrders)}
                headers={csvHeader}
                filename={"pedidos_app.csv"}
              >
                Download pedidos
              </DownloadOrdersButton>
            </div>
          )}

          <Table>
            <HeaderCell>Código do Pedido</HeaderCell>
            <HeaderCell>Nome Cliente</HeaderCell>
            <HeaderCell>Data Criação</HeaderCell>
            <HeaderCell>Seller</HeaderCell>
            <HeaderCell>Total para o Seller</HeaderCell>
            <HeaderCell>Take Seller</HeaderCell>
            <HeaderCell>Descontos</HeaderCell>
            <HeaderCell>Total Pedido</HeaderCell>
            {orders.map((order: OrderT) => {
              const { frete, produtos } = order.valores;

              const discounts = getDiscount(order.cupom, produtos, order);

              const total =
                Number(produtos) + Number(frete) - Number(discounts);

              const productsQtd = order.produtos.reduce((acc, curr) => {
                return acc + curr.quantidade;
              }, 0);

              return (
                <Row
                  onClick={() => navigator(`/order/splitted/${order.pedidoId}`)}
                  style={{ cursor: "pointer" }}
                >
                  <Cell>{order.codPedido}</Cell>
                  <Cell>{order.cliente.nome}</Cell>
                  <Cell>{formatDate(order.createdAt)}</Cell>
                  <Cell>{order.sellerName}</Cell>
                  <Cell>{formatPrice(order.amountSeller / 100)}</Cell>
                  <Cell>{`${(Number(order.takeSeller) * 100) / 100} %`}</Cell>
                  <Cell>{formatPrice(order.valores.descontos)}</Cell>
                  <Cell>{formatPrice(total)}</Cell>
                </Row>
              );
            })}
          </Table>
        </div>
      ) : (
        <div>nenhum produto encontrado</div>
      )}
    </section>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1em;
  padding: 0.5rem;
  align-items: flex-end;
`;

const DateContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const DatePicker = styled.input.attrs({
  type: "date",
})``;

const SearchInput = styled.input.attrs({
  placeholder: "Digite um código de pedido",
})`
  flex: 1;
  height: 2.5rem;
  padding: 0.5rem;
`;

const ApplyButton = styled.button`
  width: 12.5rem;
  height: 2.5rem;
`;

const LeftFilters = styled.div`
  display: flex;
  flex: 2;
  justify-content: right;
  align-items: flex-end;
`;

const DownloadOrdersButton = styled(CSVLink)`
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #85b074;
  color: #f9f8f5;
  border: 1px solid #f7f7f7;
  border-radius: 7px;
  text-decoration: none;
  cursor: pointer;
`;
