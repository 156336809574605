import styled from "styled-components";
import { ProductT } from "../../../services/types";
import { formatPrice } from "../../../utils/price";

export default function ProductList({ products }: { products: ProductT[] }) {
  return (
    <div style={{ marginBottom: "2rem" }}>
      <Title>Lista de produtos</Title>

      {products?.map((product, index) => (
        <Table key={index}>
          <thead>
            <Row>
              <HeaderCell>Nome do produto</HeaderCell>
              <HeaderCell>Quantidade</HeaderCell>
              <HeaderCell>Preço unitário</HeaderCell>
            </Row>
          </thead>
          <tbody>
            <Row>
              <Cell>{product.nome}</Cell>
              <Cell>{product.quantidade}</Cell>
              <Cell>{formatPrice(Number(product.preco))}</Cell>
            </Row>
          </tbody>
        </Table>
      ))}
    </div>
  );
}

const Title = styled.p`
  font-size: 1.31rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;
