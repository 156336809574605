import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import BarChartGraph from "../../components/BarChartGraph";
import Button from "../../components/Button";
import ComposedBarChart from "../../components/ComposedBarChart";
import DatePicker from "../../components/DatePicker";
import OrderedTable from "../../components/OrderedTable";
import { getAppReport } from "../../services/reportsService";
import theme from "../../styles/colors";
import { formatDateIntoDayMonthYear } from "../../utils/formatDateIntoDayMonthYear";

function formatReportDate({
  report,
}: {
  report: { Dia: string; Quantidade: number }[];
}) {
  return report.map((row) => ({
    ...row,
    Dia: formatDateIntoDayMonthYear(row.Dia),
  }));
}

export default function AppPerformanceReport() {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const date = new Date(new Date().getTime());
    return date.toISOString().split("T")[0];
  });

  const [appGeneralReport, setAppGeneralData] = useState<any>([]);

  async function handleGetAppGeneralReportData() {
    try {
      setIsWaitingApi(true);
      const {
        clientsReport,
        firstOrdersReport,
        ordersReport,
        revenueReport,
        tkmReport,
      } = await getAppReport({
        startDate,
        endDate,
      });

      setAppGeneralData({
        clientsReport: formatReportDate({ report: clientsReport }),
        firstOrdersReport: formatReportDate({ report: firstOrdersReport }),
        ordersReport: formatReportDate({ report: ordersReport }),
        revenueReport: formatReportDate({ report: revenueReport }),
        tkmReport,
      });
      setIsWaitingApi(false);
    } catch (error) {
      setIsWaitingApi(false);
      console.log(error);
      toast.error("Erro ao buscar resultados");
    }
  }

  useEffect(() => {
    handleGetAppGeneralReportData();
  }, []);

  return (
    <section className="wrapper">
      <Title>Relatório geral de dados do APP</Title>
      <DivFlex>
        <DivBlock>
          <DatePicker
            title="Data Início"
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Final"
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleGetAppGeneralReportData}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Atualizar"}
          </Button>
        </DivBlock>
      </DivFlex>
      <SubTitle>Cadastros no app por dia</SubTitle>
      <OrderedTable rows={appGeneralReport?.clientsReport || []} />
      <ComposedBarChart
        colors={theme.chartColors}
        data={appGeneralReport?.ordersReport || []}
        height={500}
        width={1200}
        title={"Pedidos App (#)"}
        labelKey={"Dia"}
        lineLabelKey={"Taxa de Aprovação"}
      />
      <SubTitle>Pedidos no app por dia</SubTitle>
      <OrderedTable
        rows={appGeneralReport?.firstOrdersReport?.map(
          (row: { [x: string]: number }) => ({
            ...row,
            "Taxa de 1ª compra": `${(
              (((row["Pedidos de 1ª compra cliente novo"] || 0) +
                (row["Pedidos de 1ª compra cliente existente"] || 0)) /
                row["Pedidos Feitos"] || 0) * 100
            ).toFixed(2)}%`,
            "Taxa de conversão de 1ª compra": `${(
              (((row["Pedidos de 1ª compra cliente novo"] || 0) +
                (row["Pedidos de 1ª compra cliente existente"] || 0)) /
                row["Cadastros"] || 0) * 100
            ).toFixed(2)}%`,
          })
        )}
      />
      <ComposedBarChart
        colors={theme.chartColors}
        data={appGeneralReport?.revenueReport || []}
        height={500}
        width={1200}
        title={"Receita por dia"}
        labelKey={"Dia"}
        lineLabelKey={"Taxa de Aprovação"}
      />
      <BarChartGraph
        colors={theme.chartColors}
        data={appGeneralReport?.tkmReport || []}
        height={500}
        width={1200}
        title={"Ticket médio por dia"}
        labelKey={"Dia"}
        shouldStack={false}
      />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  text-align: center;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  input {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
