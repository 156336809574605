import { useEffect, useState } from "react";
import styled from "styled-components";
import { getCategoriesWithDetails } from "../../services/categoriesService";
import { FormattedCategory } from "../../services/types";
import CategoriesListTable from "./CategoriesListTable";
import { TableLink } from "./CategoryListItem";

export default function CategoryList() {
  const [categories, setCategories] = useState<FormattedCategory[] | null>(
    null
  );

  const handleGetCategories = async () => {
    const response = await getCategoriesWithDetails();
    if (response.length) setCategories(response);
  };

  useEffect(() => {
    handleGetCategories();
  }, []);

  return (
    <section className="wrapper">
      <Title>Categorias</Title>
      <TableLink to={"/category"}>Ordenar categoria</TableLink>
      {categories && <CategoriesListTable categories={categories} />}
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;
