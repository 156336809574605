import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import OrderedTable from "../../components/OrderedTable";
import { getAppShippingReport } from "../../services/reportsService";
import { formatDateIntoDayMonthYear } from "../../utils/formatDateIntoDayMonthYear";
import { formatNumberToBRL } from "../../utils/formatNumberToBRL";
import BrazilSvgMap from "./BrazilSvgMap";

export default function ShippingReport() {
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const date = new Date(new Date().getTime());
    return date.toISOString().split("T")[0];
  });

  const [appShippingReport, setAppShippingReport] = useState<any>([]);

  async function handleGetAppShippingReportData() {
    try {
      setIsWaitingApi(true);
      const response = await getAppShippingReport({
        startDate,
        endDate,
      });

      setAppShippingReport(response);
      setIsWaitingApi(false);
    } catch (error) {
      setIsWaitingApi(false);
      console.log(error);
      toast.error("Erro ao buscar resultados");
    }
  }

  useEffect(() => {
    handleGetAppShippingReportData();
  }, []);

  return (
    <section className="wrapper">
      <Title>Relatório de frete do App</Title>
      <DivFlex>
        <DivBlock>
          <DatePicker
            title="Data Início"
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Final"
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={handleGetAppShippingReportData}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Atualizar"}
          </Button>
        </DivBlock>
      </DivFlex>

      <SubTitle>Pedidos feitos por estado</SubTitle>
      <BrazilSvgMap
        ordersByState={
          Object.keys(
            appShippingReport?.ordersByState?.reducedOrders || {}
          ).map((key) => ({
            Estado: key,
            Pedidos: appShippingReport.ordersByState?.reducedOrders[key],
            Total:
              appShippingReport.ordersByState?.reducedOrdersWithTotals[key],
            TKM:
              appShippingReport.ordersByState?.reducedOrdersWithTotals[key] /
              appShippingReport.ordersByState?.reducedOrders[key],
          })) || []
        }
      />

      <SubTitle>Custos de frete</SubTitle>
      <OrderedTable
        rows={
          appShippingReport?.shippingCosts?.map((row: { Dia: string }) => ({
            ...row,
            Dia: formatDateIntoDayMonthYear(row.Dia),
          })) || []
        }
        rowsFormatting={{
          "Custos de Frete": formatNumberToBRL,
          "Frete de SP": formatNumberToBRL,
          "Frete de SP Capital": formatNumberToBRL,
        }}
      />

      {appShippingReport?.shippingCosts && (
        <OrderedTable
          rowsFormatting={{
            "Custos de Frete": formatNumberToBRL,
            "Frete de SP": formatNumberToBRL,
            "Frete de SP Capital": formatNumberToBRL,
          }}
          rows={[
            appShippingReport?.shippingCosts?.reduce(
              (acc: { [x: string]: number }, crr: { [x: string]: number }) => ({
                Total: "Total",
                "Custos de Frete":
                  acc["Custos de Frete"] + Number(crr["Custos de Frete"] || 0),
                "Frete Gratis total":
                  acc["Frete Gratis total"] +
                  Number(crr["Frete Gratis total"] || 0),
                "Frete de SP":
                  acc["Frete de SP"] + Number(crr["Frete de SP"] || 0),
                "Frete de SP Capital":
                  acc["Frete de SP Capital"] +
                  Number(crr["Frete de SP Capital"] || 0),
                "Frate Gratis SP":
                  acc["Frate Gratis SP"] + Number(crr["Frate Gratis SP"] || 0),
              }),
              {
                Total: "Total",
                "Custos de Frete": 0,
                "Frete Gratis total": 0,
                "Frete de SP": 0,
                "Frete de SP Capital": 0,
                "Frate Gratis SP": 0,
              }
            ),
          ]}
        />
      )}

      <SubTitle>Custos de frete por transportadora</SubTitle>
      <OrderedTable
        rows={
          appShippingReport?.costsByCarrier?.map(
            (row: { Dia: any; Transportadora: any; Valor: any }) => ({
              Dia: formatDateIntoDayMonthYear(row.Dia),
              [row.Transportadora]: Number(row.Valor),
            })
          ) || []
        }
        rowsFormatting={{
          express90: formatNumberToBRL,
          jadlog1: formatNumberToBRL,
          "la-transportes": formatNumberToBRL,
          jadlog3: formatNumberToBRL,
        }}
      />

      <SubTitle>Quantidades de fretes</SubTitle>
      <OrderedTable
        rows={
          appShippingReport?.costsByCarrier?.map(
            (row: { Dia: any; Transportadora: any; Quantidade: any }) => ({
              Dia: formatDateIntoDayMonthYear(row.Dia),
              [row.Transportadora]: Number(row.Quantidade),
            })
          ) || []
        }
      />
      {appShippingReport?.costsByCarrier && (
        <OrderedTable
          rows={[
            appShippingReport?.costsByCarrier?.reduce(
              (
                acc: { [key: string]: number },
                crr: { Transportadora: string; Quantidade: number }
              ) => {
                const carrier = crr.Transportadora;
                const quantity = Number(crr.Quantidade);

                if (isNaN(quantity)) {
                  // Skip if Quantidade is not a valid number
                  return acc;
                }

                if (!acc[carrier]) {
                  acc[carrier] = 0;
                }

                acc[carrier] += quantity;

                return acc;
              },
              { Total: "Total" }
            ),
          ]}
        />
      )}
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const SubTitle = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  text-align: center;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  input {
    margin-bottom: 0;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
