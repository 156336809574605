import { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import Dropdown from "../../components/BannerManager/Dropdown";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import DatePicker from "../../components/DatePicker";
import ReportsItemList from "../../components/ReportsItemList";
import { getSearches } from "../../services/reportsService";

export interface SearchType {
  tka_in_click_amount: number;
  tka_st_device: DevicesTypes["device"];
  tka_st_query: string;
  tka_st_query_result: number;
  tka_in_times_searched: number;
}

export interface DevicesTypes {
  device: "ios" | "android" | "todos";
}

export default function SearchReports() {
  const [query, setQuery] = useState<string>("");
  const [device, setDevice] = useState<DevicesTypes["device"]>("todos");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });
  const [startDate, setStartDate] = useState<string>(() => {
    const date = new Date(new Date().getTime() - 8 * 24 * 60 * 60 * 1000);
    return date.toISOString().split("T")[0];
  });

  const [searches, setSearches] = useState<SearchType[]>();

  const handleGetSearche = useCallback(async () => {
    setIsWaitingApi(true);
    const res = await getSearches({ device, query, endDate, startDate });
    setSearches(res);
    setIsWaitingApi(false);
  }, [device, endDate, query, startDate]);

  useEffect(() => {
    handleGetSearche();
  }, []);

  return (
    <section className="wrapper">
      <Title>Relatório da Busca</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Termo"
            setValue={setQuery}
            value={query}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <Dropdown
            options={[
              {
                title: "Todos",
                value: "todos",
              },
              {
                title: "IOS",
                value: "ios",
              },
              {
                title: "Android",
                value: "android",
              },
            ]}
            setSelected={setDevice}
            title={"Dispositivo"}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Início"
            value={startDate}
            onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
        <DivBlock>
          <DatePicker
            title="Data Final"
            value={endDate}
            onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
          />
        </DivBlock>
      </DivFlex>
      <DivFlex>
        <Button
          variant="light"
          onClick={handleGetSearche}
          disabled={isWaitingApi}
        >
          {isWaitingApi ? "Carregando..." : "Buscar"}
        </Button>
        {searches?.length && !isWaitingApi ? (
          <DownloadAsCSVButton
            data={searches.map((searchItem) => searchItem)}
            headers={Object.keys(searches[0]).map((key) => ({
              label: key,
              key,
            }))}
            filename={"buscas.csv"}
          >
            Baixar CSV
          </DownloadAsCSVButton>
        ) : null}
      </DivFlex>
      <ReportsItemList rows={searches} />
    </section>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

const DownloadAsCSVButton = styled(CSVLink)`
  margin-top: 1rem;
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text_light};
  font-weight: 600;
  width: max-content;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;
