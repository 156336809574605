import styled from "styled-components";
import { OrderT, SplitT } from "../../../services/types";
import { formatPrice } from "../../../utils/price";

export default function SplitList({ order }: { order: OrderT }) {
  const split: SplitT[] = order.split!;
  return (
    <div style={{ marginTop: "2rem" }}>
      <Title>Split</Title>

      {split?.map((spl) => (
        <Table>
          <Row>
            <HeaderCell>Nome do Seller</HeaderCell>
            <HeaderCell>Total</HeaderCell>
            <HeaderCell>Take Seller</HeaderCell>
          </Row>
          <Row>
            <Cell>{spl.recipient.name}</Cell>
            <Cell>{formatPrice(Number(spl.amount / 100))}</Cell>
            <Cell>{`${(Number(order.takeSeller) / 100) * 100}%`}</Cell>
          </Row>
        </Table>
      ))}
    </div>
  );
}

const Title = styled.p`
  font-size: 1.31rem;
  font-weight: bold;
  margin-bottom: 2rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  table-layout: fixed;
  font-size: 1rem;
`;

const Row = styled.tr``;

const Cell = styled.td`
  border-bottom: 1px solid #d6d6d6;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

const HeaderCell = styled.th`
  padding: 0.5rem;
`;
