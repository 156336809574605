import { parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { DateRangePicker as ReactDateRangePicker } from "react-date-range";

interface ISelection {
  endDate: Date;
  startDate: Date;
  key: string;
}

export interface IDateRangePickerResponse {
  selection: ISelection;
}

interface IDateRangerPicker {
  onChange: (range: any) => void;
  startDate: string;
  endDate: string;
}

export function DateRangePicker({
  onChange,
  startDate,
  endDate,
}: IDateRangerPicker) {
  return (
    <ReactDateRangePicker
      ranges={[
        {
          startDate: parseISO(startDate),
          endDate: parseISO(endDate),
          key: "selection",
        },
      ]}
      onChange={onChange}
      showDateDisplay={false}
      staticRanges={[]}
      inputRanges={[]}
      locale={ptBR}
    />
  );
}
