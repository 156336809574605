const configs = {
  apiBaseUrl: "https://admin-app-api.doctorlib.com.br", //Production
  // apiBaseUrl: "http://localhost:3333", //Development

  appApiBaseUrl: "https://www.sonoma-pedidos.doctorlib.com.br", //Production
  // appApiBaseUrl: "http://192.168.1.103:3333", //Development

  firebaseConfig: {
    apiKey: "AIzaSyDOgrqc8PYLafZYXfiQbTottJzkO45XYW8",
    authDomain: "sonoma-pedidos-api.firebaseapp.com",
    databaseURL: "https://sonoma-pedidos-api.firebaseapp.com",
    projectId: "sonoma-pedidos-api",
    storageBucket: "sonoma-pedidos-api.appspot.com",
    messagingSenderId: "965990177009",
    appId: "1:965990177009:web:679992d6188b2db84df150",
  },
};

export default configs;
