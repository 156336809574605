import { Cell, Pie, PieChart } from "recharts";
import styled from "styled-components";

export default function PieChartGraph({
  data,
  labelKey,
  width,
  height,
  title,
  colors,
}: {
  data: any[];
  labelKey: string;
  width: number;
  height: number;
  title?: string;
  colors: string[];
}) {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + (radius / 6) * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN) * 1.5;

    return (
      <text
        x={x}
        y={y}
        fill="#3b3b3a"
        fontSize={12}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontWeight={"bold"}
      >
        {`${data[index][labelKey]} - ${Number(percent * 100).toFixed(2)}%`}
      </text>
    );
  };

  return (
    <GraphContainer>
      <Title>{title}</Title>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={width / 3}
          fill="#8884d8"
          dataKey="value"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
      </PieChart>
    </GraphContainer>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;
