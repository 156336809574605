import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { BannerT } from "../../services/types";
import Modal from "../Modal";
import BannerForm from "./BannerForm";
import BannerOrderList from "./BannerOrderList";

export interface BannerLocationOptions {
  location:
    | "stripe"
    | "main"
    | "slim1"
    | "slim2"
    | "slim3"
    | "slim4"
    | "slim5"
    | "search"
    | "category"
    | "cluster";
}

export default function BannerManager({
  banners,
  location,
  title,
}: {
  banners: BannerT[];
  location: BannerLocationOptions["location"];
  title: string;
}) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [selectedBanner, setSelectedBanner] = useState<BannerT | null>(null);

  function getBannerById(id: number | null) {
    if (!id) return setSelectedBanner(null);

    const banner = banners.find((b) => b.banner_id === id);

    if (banner) {
      setSelectedBanner(banner);
      setIsOpenModal(true);
    }
  }

  //get banner list
  //show banner list
  //if changed enable save button
  //
  //Create add banner functionality
  //
  return (
    <BannerManagerMain>
      <strong>{title}</strong>
      <small>{`Customização do ${title.toLowerCase()}`}</small>
      <BannerOrderList
        shouldUpdate={isOpenModal}
        openModal={setIsOpenModal}
        banners={banners}
        selectBanner={getBannerById}
      />
      <Modal isOpen={isOpenModal} setOpen={setIsOpenModal}>
        <BannerForm
          setIsOpenModal={setIsOpenModal}
          location={location}
          banner={selectedBanner}
          title={title.toLowerCase()}
        />
      </Modal>
    </BannerManagerMain>
  );
}

export const BannerManagerMain = styled.div`
  margin: 3rem 0;
  border-top: 1px solid ${(props) => props.theme.colors.border};
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
`;
