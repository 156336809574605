import { CupomT, OrderT } from "../services/types";

function formatPrice(value: number) {
  return `R$ ${value.toFixed(2)}`.replace(".", ",");
}

function getDiscount(cupom: CupomT, subtotal: number, order: OrderT) {
  if (!cupom) return 0;

  const products = order.produtos;

  if (cupom && cupom.cup_st_descricao === "Signature") {
    const isExistSignatureProducts = products.filter(
      (prod) => Number(prod.take) >= 30.0
    );
    const discountSignatureTotal = isExistSignatureProducts.reduce(
      (acc, curr) => {
        const discount =
          Number(curr.preco) *
          (Number(cupom.cup_de_porcentagem) / 100) *
          curr.quantidade;
        return (acc += discount);
      },
      0
    );

    return discountSignatureTotal;
  }

  const discounts =
    subtotal * (Number(cupom?.cup_de_porcentagem) / 100) +
    Number(cupom?.cup_de_valor);

  return discounts;
}

export { formatPrice, getDiscount };
