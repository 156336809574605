import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import FormInput from "../../components/BannerManager/FormInput";
import Button from "../../components/Button";
import OrderedTable from "../../components/OrderedTable";
import { getKits } from "../../services/kitsService";
import { FormattedKit, Kit } from "../../services/types";
import { formatNumberToBRL } from "../../utils/formatNumberToBRL";
import getImageDownloadUrlFromFireBase from "../../utils/getImageDownloadUrlFromFireBase";
import { ProductImage } from "../ProductRestrictions/ProductSelectorForRestriction";

export default function Kits() {
  const navigate = useNavigate();
  const [kits, setKits] = useState<FormattedKit[]>();
  const [search, setSearch] = useState<string>("");
  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);

  async function handleGetKits() {
    try {
      const res = await getKits();

      if (res?.data)
        setKits(
          await _formatKitsForList({
            kits: res.data,
            handleEditKit: (id) => navigate(`/kits/${id}`),
          })
        );
    } catch (error) {
      toast.warn(`Erro ao carregar Kits \nErro:\n${error}`);
    }
  }

  useEffect(() => {
    setIsWaitingApi(true);
    handleGetKits();
    setIsWaitingApi(false);
  }, []);

  return (
    <section className="wrapper">
      <Title>Kits do aplicativo</Title>
      <DivFlex>
        <DivBlock>
          <FormInput
            title="Busca"
            setValue={setSearch}
            value={search}
            width="250px"
            height="2rem"
            borderRadius="2px"
          />
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => setSearch("")}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Limpar"}
          </Button>
        </DivBlock>
        <DivBlock>
          <Button
            variant="light"
            onClick={() => navigate(`/kits/create`)}
            disabled={isWaitingApi}
          >
            {isWaitingApi ? "Carregando..." : "Criar Kit"}
          </Button>
        </DivBlock>
      </DivFlex>

      <OrderedTable
        rows={kits?.filter((item: { Nome: string }) => {
          if (search) {
            return item.Nome.toLowerCase().includes(search.toLowerCase());
          }
          return true;
        })}
        rowsFormatting={{
          "Preço Por": formatNumberToBRL,
          "Preço De": formatNumberToBRL,
        }}
      />
    </section>
  );
}

async function _formatKitsForList({
  kits,
  handleEditKit,
}: {
  kits: Kit[];
  handleEditKit: (id: string) => void;
}) {
  return await Promise.all(
    kits.map(async (item) => {
      const productImg = await getImageDownloadUrlFromFireBase(
        item.imagens?.[0]?.pim_st_imagesrc
      );

      return {
        Foto: (
          <ProductImage
            src={
              productImg ||
              "https://bo-prod-test.sonoma.com.br/media/wysiwyg/wine-loading.gif"
            }
            alt={item.pro_st_descricao}
          />
        ),
        Nome: item.pro_st_descricao,
        "Preço De": Number(item.pro_de_precode),
        "Preço Por": Number(item.pro_de_precopor),
        Editar: (
          <Button
            variant="light"
            onClick={() => handleEditKit(item.pro_in_codigo)}
          >
            Editar
          </Button>
        ),
      };
    })
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3rem;
`;

const DivBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;

  input {
    margin-bottom: unset;
  }
`;

const DivFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;
