import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styled from "styled-components";

export default function BarChartGraph({
  data,
  labelKey,
  width,
  height,
  title,
  colors,
  shouldStack = true,
}: {
  data: any[];
  labelKey: string;
  width: number;
  height: number;
  title?: string;
  colors: string[];
  shouldStack?: boolean;
}) {
  function getUniqueKeys(data: any[]): string[] {
    const keys: string[] = [];

    for (const obj of data) {
      for (const key in obj) {
        if (!keys.includes(key)) {
          keys.push(key);
        }
      }
    }

    return keys;
  }

  return (
    <GraphContainer>
      <Title>{title}</Title>
      <BarChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={labelKey} />
        <YAxis />
        <Tooltip />
        <Legend />
        {getUniqueKeys(data).map(
          (key, index) =>
            labelKey !== key && (
              <Bar
                key={index}
                dataKey={key}
                stackId={shouldStack ? "a" : index}
                fill={colors[index % colors.length]}
              />
            )
        )}
      </BarChart>
    </GraphContainer>
  );
}

const Title = styled.p`
  color: ${(props) => props.theme.colors.text_dark};
  font-size: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;
