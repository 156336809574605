import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { BannerLocationOptions } from ".";
import {
  getSubLocationTitle,
  postCreateBanner,
  putUpdateBanner,
} from "../../services/bannersService";
import { getCategories } from "../../services/categoriesService";
import { getCollections } from "../../services/collectionService";
import { BannerT } from "../../services/types";
import Button from "../Button";
import DatePicker from "../DatePicker";
import Dropdown from "./Dropdown";
import FilePicker from "./FormFilePicker";
import Input from "./FormInput";
import ProductSelector from "./ProductSelector";

export default function BannerForm({
  setIsOpenModal,
  location,
  banner,
  title,
}: {
  setIsOpenModal: Function;
  location: BannerLocationOptions["location"];
  banner?: BannerT | null;
  title?: string;
}) {
  const isEdit = !!banner;

  const [isWaitingApi, setIsWaitingApi] = useState<boolean>(false);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  const [cat, setCat] = useState<string>(
    isEdit ? `${banner?.category_id}` : ""
  );
  const [subLocation, setSubLocation] = useState<string>(
    isEdit ? `${banner?.banner_sublocation}` : ""
  );
  const [col, setCol] = useState<string>(
    isEdit ? `${banner?.department_id}` : ""
  );
  const [prod, setProd] = useState<string>(
    isEdit ? `${banner?.product_id}` : ""
  );
  const [url, setUrl] = useState<string>(
    isEdit ? `${banner?.url_external}` : ""
  );
  const [bannerTitle, setBannerTitle] = useState<string>(
    isEdit ? `${banner?.banner_title}` : ""
  );
  const [redirectType, setRedirectType] = useState(
    isEdit ? getBannerType() : null
  );
  const [startDate, setStartDate] = useState<string>(
    banner?.banner_start_date ? banner.banner_start_date : ""
  );
  const [endDate, setEndDate] = useState<string>(
    banner?.banner_end_date ? banner.banner_end_date : ""
  );
  const mobileFile = useRef<HTMLInputElement>(null);
  const desktopFile = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setFormIsValid(false);
    if (redirectType === "vazio" || "url") {
      setFormIsValid(true);
    }
  }, [redirectType]);

  useEffect(() => {
    // Todo: Adicionar validações de verdade
    if (cat.length > 0 || col.length > 0) setFormIsValid(true);
  }, [cat, col]);

  const bannerTypes = [
    {
      title: "Coleçao",
      value: "colecao",
    },
    {
      title: "Categoria",
      value: "categoria",
    },
    {
      title: "Produto",
      value: "produto",
    },
    {
      title: "URL externa",
      value: "url",
    },
    {
      title: "Sem redirect",
      value: "vazio",
    },
  ];

  function getBannerType() {
    let type = "";
    // get banner type based on category id, collection, product id or url
    if (banner?.category_id) type = "categoria";
    if (banner?.department_id) type = "colecao";
    if (banner?.product_id) type = "produto";
    if (banner?.url_external) type = "url";
    if (
      !banner?.category_id &&
      !banner?.department_id &&
      !banner?.product_id &&
      !banner?.url_external
    )
      type = "vazio";

    return type;
  }

  function getFormData(formData: FormData, subLocationTitle: string) {
    const types = {
      categoria: {
        categoryId: cat,
        productId: "",
        departamentId: "",
        externalUrl: "",
      },
      produto: {
        categoryId: "",
        productId: prod,
        departamentId: "",
        externalUrl: "",
      },
      colecao: {
        categoryId: "",
        productId: "",
        departamentId: col,
        externalUrl: "",
      },
      url: {
        categoryId: "",
        productId: "",
        departamentId: "",
        externalUrl: url,
      },
      vazio: {
        categoryId: "",
        productId: "",
        departamentId: "",
        externalUrl: "",
      },
    };

    if (isEdit) {
      for (const [key, valor] of Object.entries(types)) {
        if (key === redirectType) {
          for (const [key, value] of Object.entries(valor)) {
            formData.append(key, value);
          }
        }
      }

      formData.append("bannerTitle", bannerTitle);
      formData.append("location", location);
      formData.append("sublocation", subLocation);
      formData.append("sublocationTitle", subLocationTitle);
      formData.append("startDate", startDate);
      formData.append("endDate", endDate);

      return formData;
    }

    formData.append("categoryId", cat);
    formData.append("productId", prod);
    formData.append("departamentId", col);
    formData.append("externalUrl", url);
    formData.append("bannerTitle", bannerTitle);
    formData.append("location", location);
    formData.append("sublocation", subLocation);
    formData.append("sublocationTitle", subLocationTitle);
    formData.append("startDate", startDate);
    formData.append("endDate", endDate);

    return formData;
  }

  async function handleFormSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    let responseUpdateBanner;
    let responseCreateBanner;
    //todo validate entries
    const subLocationTitle = await getSubLocationTitle({
      location,
      id: subLocation,
    });

    setIsWaitingApi(true);

    const formData = new FormData();
    getFormData(formData, subLocationTitle);

    if (mobileFile.current?.files?.[0])
      formData.append("bannerImage", mobileFile.current.files[0]);
    if (desktopFile.current?.files?.[0])
      formData.append("desktopBannerImage", desktopFile.current.files[0]);

    try {
      if (isEdit)
        responseUpdateBanner = await putUpdateBanner({
          banner_id: banner.banner_id,
          formData,
        });
      else responseCreateBanner = await postCreateBanner({ formData });
      if (!responseUpdateBanner && !responseCreateBanner) {
        setIsOpenModal(true);
      } else {
        setIsOpenModal(false);
        setTimeout(() => window.location.reload(), 5000);
      }
    } catch (error: any) {
      alert(error.message);
    }
    setIsWaitingApi(false);
  }

  return (
    <Form>
      {isEdit ? (
        <strong>Editar Banner</strong>
      ) : (
        <strong>Adicionar Banner</strong>
      )}

      <Input
        title="Título do Banner"
        placeholder="Digite um título aqui"
        setValue={setBannerTitle}
        type="text"
        value={bannerTitle}
        required
        width="250px"
        height="2rem"
        borderRadius="2px"
      />
      <Dropdown
        required={true}
        title={`Escolha o tipo de redirect`}
        selected={redirectType}
        setSelected={setRedirectType}
        options={bannerTypes}
      />
      {redirectType === "colecao" && (
        <Dropdown
          required={true}
          title={`Escolha uma coleção ${col ? `Id: ${col}` : ""}`}
          selected={col}
          setSelected={setCol}
          options={async () => await getCollections()}
        />
      )}
      {redirectType === "categoria" && (
        <Dropdown
          required={true}
          title={`Escolha uma categoria ${cat ? `Id: ${cat}` : ""}`}
          selected={cat}
          setSelected={setCat}
          options={async () => await getCategories()}
        />
      )}
      {redirectType === "produto" && (
        <ProductSelector
          initialProd={prod}
          setProd={setProd}
          setFormIsValid={setFormIsValid}
        />
      )}

      {redirectType === "url" && (
        <Input
          required={true}
          title="Em caso de Url externa digite abaixo ou deixe em branco para usar a navegação de dentro do aplicativo"
          placeholder="Digite uma URL da web aqui"
          setValue={setUrl}
          type="text"
          value={url}
          width="250px"
          height="2rem"
          borderRadius="2px"
        />
      )}
      {location === "category" && (
        <Dropdown
          required={true}
          title={`Escolha a categoria em que o banner vai aparecer ${
            subLocation ? `Id: ${subLocation}` : ""
          }`}
          selected={subLocation}
          setSelected={setSubLocation}
          options={async () => await getCategories()}
        />
      )}
      {location === "cluster" && (
        <Dropdown
          required={true}
          title={`Escolha a coleção em que o banner vai aparecer ${
            subLocation ? `Id: ${subLocation}` : ""
          }`}
          selected={subLocation}
          setSelected={setSubLocation}
          options={async () => await getCollections()}
        />
      )}
      <DatePicker
        title="Data de início"
        value={
          startDate && startDate !== "0000-00-00"
            ? new Date(startDate).toISOString().substring(0, 10)
            : ""
        }
        onChange={(e) => setStartDate((e.target as HTMLInputElement).value)}
      />
      <DatePicker
        title="Data de término"
        value={
          endDate && endDate !== "0000-00-00"
            ? new Date(endDate).toISOString().substring(0, 10)
            : ""
        }
        onChange={(e) => setEndDate((e.target as HTMLInputElement).value)}
      />
      {!isEdit && (
        <>
          <FilePicker
            required={true}
            title="Escolha a imagem do banner para mobile"
            placeholder="Digite uma URL da web aqui"
            type="file"
            accept="image/jpeg"
            inputRef={mobileFile}
            customAlert={
              title?.includes("tarja")
                ? "O banner deve ter 400px por 40px, sendo JPEG/JPG com no máximo 120kb"
                : "O banner deve ter 600px por 200px, sendo JPEG/JPG com no máximo 120kb"
            }
          />
          <FilePicker
            required={true}
            title="Escolha a imagem do banner"
            placeholder="Digite uma URL da web aqui"
            type="file"
            accept="image/jpeg"
            inputRef={desktopFile}
            customAlert={
              "O banner deve ter 1920px por 40px, sendo JPEG/JPG com no máximo 120kb"
            }
          />
        </>
      )}
      <Button
        variant="light"
        onClick={handleFormSubmit}
        disabled={isWaitingApi || !formIsValid}
      >
        {isWaitingApi ? "Enviando..." : "Salvar"}
      </Button>
    </Form>
  );
}

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  strong {
    font-size: 1.5rem;
  }
`;
