import styled from "styled-components";

export default function ReportsItemList({ rows }: { rows: any[] | undefined }) {
  return (
    <>
      {rows?.length ? (
        <TableOfContents>
          <thead>
            <tr>
              {Object.keys(rows[0]).map((key) => (
                <th>{key}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr>
                {Object.keys(row).map((key) => (
                  <td>{row[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </TableOfContents>
      ) : (
        <LoadingText>
          Nenhum resultado encontrado, tente ampliar a busca.
        </LoadingText>
      )}
    </>
  );
}

const TableOfContents = styled.table`
  font-size: 16px;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  color: ${(props) => props.theme.colors.text_dark};
  margin: 3rem 0;

  tbody,
  thead {
    tr {
      border-bottom: 1px solid ${(props) => props.theme.colors.border};
      background-color: #eee;
      th {
        padding-top: 11px;
        padding-bottom: 11px;
        background-color: ${(props) => props.theme.colors.primary};
        color: white;
        text-align: left;
        padding: 8px;
        border: 1px solid ${(props) => props.theme.colors.text_light};
      }
      td {
        text-align: left;
        padding: 8px;
        display: table-cell;
        vertical-align: top;
        border: 1px solid ${(props) => props.theme.colors.border};
      }
    }
    tr:nth-child(odd) {
      background-color: ${(props) => props.theme.colors.background_light};
    }
  }
`;

const LoadingText = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 3rem 0;
`;
