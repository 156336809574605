export const mockHeaders = [
  { label: "Sku Mln", key: "productSku" },
  { label: "Quantidade", key: "productAmount" },
  { label: "Nome do produto (opcional)", key: "productName" },
];
export const mockData = [
  {
    productSku: "701816",
    productAmount: "10",
    productName: `Saca Rolhas Sonoma - Prata`,
  },
  {
    productSku: "701794",
    productAmount: "20",
    productName: `BRESSIA SYLVESTRA MALBEC (ORGÂNICO)`,
  },
  {
    productSku: "701596",
    productAmount: "30",
    productName: `KAIKEN TERROIR SERIES MALBEC 2017`,
  },
  {
    productSku: "701707",
    productAmount: "40",
    productName: `GILLMORE “MARIPOSA” RED BLEND`,
  },
  {
    productSku: "701705",
    productAmount: "50",
    productName: `Gillmore “Vigno“ Vignadores de Carignan 2011`,
  },
];
