import styled from "styled-components";
import { FormattedCategory } from "../../services/types";
import { Link } from "react-router-dom";

export default function CategoryListItem({
  id,
  description,
  orderby,
  imgPath,
  deleted,
  enabled,
  updatedAt,
  childrenCategories,
}: FormattedCategory) {
  return (
    <>
      <tr>
        <td>{id}</td>
        <td>{description}</td>
        <td>{orderby}</td>
        <td>{!deleted && enabled ? "Sim" : "Não"}</td>
        <td>
          <TableLink
            to={`/category/${id}`}
            state={{ categoryName: description }}
          >
            Editar
          </TableLink>
        </td>
        <td>{new Date(updatedAt).toLocaleString()}</td>
      </tr>
      {!!childrenCategories?.length &&
        childrenCategories.map((subCategory) => (
          <CategoryListItem
            {...subCategory}
            description={`${description} \\ ${subCategory.description}`}
            key={subCategory.id}
          />
        ))}
    </>
  );
}

export const TableLink = styled(Link)`
  border: none;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 0;
  height: 2.5rem;
  padding: 0 2rem;
  cursor: pointer;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text_light};
  font-weight: 600;
  width: max-content;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: unset;
    opacity: 80%;
  }
`;
