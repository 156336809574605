import BannerManager, { BannerLocationOptions } from ".";
import { BannerT } from "../../services/types";

export default function BannersAggregator({
  banners,
  location,
  title,
}: {
  banners: Array<BannerT>;
  location: BannerLocationOptions["location"];
  title: string;
}) {
  let _aggregatedBanners = {} as { [key: string]: Array<BannerT> };
  banners.forEach((_banner) => {
    if (!_aggregatedBanners[_banner.banner_sublocation])
      _aggregatedBanners[_banner.banner_sublocation] = [];

    _aggregatedBanners[_banner.banner_sublocation].push(_banner);
  });

  return Object.keys(_aggregatedBanners).length ? (
    <>
      {Object.keys(_aggregatedBanners).map((_bannerGroup) => {
        return (
          <BannerManager
            key={_bannerGroup}
            location={location}
            banners={_aggregatedBanners[_bannerGroup]}
            title={`${title} ${_aggregatedBanners[
              _bannerGroup
            ][0].banner_sublocation_title.toUpperCase()} ID:${_bannerGroup}`}
          />
        );
      })}
    </>
  ) : (
    <BannerManager location={location} banners={banners} title={`${title}`} />
  );
}
