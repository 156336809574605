import { useEffect } from "react";
import configs from "../../appConfigs";
import AuthContext from "../../auth/authProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { auth } = useContext(AuthContext);
  const navigator = useNavigate();

  useEffect(() => {
    if (auth?.refresh_token) navigator("/banners");
    else window.location.replace(`${configs.apiBaseUrl}/api/login`);
    // eslint-disable-next-line
  }, []);

  return null;
}
