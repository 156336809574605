import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body{
        background-color: ${props => props.theme.colors.background_light};;
        color: ${props => props.theme.colors.text_dark};
        font-family: sans-serif;
    }

    .wrapper{
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        display: block;
        padding: 0 1rem;
    }
`