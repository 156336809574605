import axios from "axios";
import configs from "../appConfigs";

export async function getTypesDictionary() {
  const dictionary = await axios.get(
    `${configs.apiBaseUrl}/api/typesDictionary`
  );

  return dictionary.data;
}

export async function createDictionaryItem(termo: any) {
  await axios.post(`${configs.apiBaseUrl}/api/dictionary`, termo);
}

export async function editDictionaryItem(termo: any) {
  await axios.put(`${configs.apiBaseUrl}/api/dictionary`, termo);
}

export async function deleteDictionaryItem(termo: string) {
  await axios.delete(`${configs.apiBaseUrl}/api/dictionary/${termo}`);
}

export async function getPontuacoes() {
  const pontuacoes = await axios.get(
    `${configs.apiBaseUrl}/api/punctuationDictionary`
  );

  return pontuacoes.data;
}
