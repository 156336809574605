import { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";

export interface DropdownOption {
  title: string;
  value: string;
  selected?: boolean;
}

export default function Dropdown({
  title,
  options,
  setSelected,
  required,
  selected,
}: {
  required?: boolean;
  title?: string;
  options: DropdownOption[] | (() => Promise<DropdownOption[]>);
  setSelected: Function;
  selected?: any;
}) {
  const [_options, _setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (typeof options !== "function") _setOptions(options);
    else getOptions(options);

    // eslint-disable-next-line
  }, []);

  async function getOptions(callableOptions: () => Promise<DropdownOption[]>) {
    const newOptions = await callableOptions();
    if (newOptions.length) _setOptions(newOptions);
  }

  function handleOnChange(e: ChangeEvent<HTMLSelectElement>) {
    setSelected(e.target.value);
  }

  return (
    <>
      {title && <Label htmlFor={title}>{title}</Label>}
      <Select
        name={title}
        onChange={handleOnChange}
        defaultValue={""}
        required={required}
      >
        <option disabled value="">
          {" "}
          -- escolha uma --{" "}
        </option>
        {_options.map((option) => (
          <option
            value={option.value}
            key={option.value}
            selected={option.value === selected}
          >
            {option.title}
          </option>
        ))}
      </Select>
    </>
  );
}

const Label = styled.label`
  margin-top: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
`;

const Select = styled.select`
  width: 250px;
  height: 2rem;
  border: 1px solid ${(props) => props.theme.colors.border};
  margin: 0.5rem 0;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.text_dark};
  padding: 0.25rem;
  border-radius: 2px;
`;
